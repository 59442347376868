import React, { ReactPropTypes } from 'react';
import { Add } from '@mui/icons-material';
import css from './styles/DocumentUpload.module.scss';

interface Props extends Partial<ReactPropTypes> {
  open: Function;
  isLoading?: boolean;
}

const DocumentUpload: React.FC<Props> = ({ open, isLoading }: Props) => {
  return (
    <div key="newDocumentButton" onClick={() => open()} className={`${css.uploadWrapper} ${isLoading ? css.dim : ''}`}>
      <Add aria-label="New Document" />
      <label className={css.uploadLabel}>
        <span>Choose a file</span> or drag it here
      </label>
    </div>
  );
};

export default DocumentUpload;
