import { Button, Dialog } from '@mui/material';
import { ReactPropTypes } from 'react';
import { useTranslation } from 'react-i18next';
import css from './styles/SaveContinueDialog.module.scss';

interface Props extends Partial<ReactPropTypes> {
  onSaveAndContinue: Function;
  onSaveAndClose: Function;
  open: boolean;
  title?: string;
  message?: string;
}

function SaveAndContinueDialog({ onSaveAndContinue, onSaveAndClose, open, title, message }: Props) {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={() => onSaveAndClose()} classes={{ paper: css.dialogContainer }} style={{ zIndex: 99999 }}>
      <div className={css.content}>
        <div className={css.title}>{title || t('Do you want to continue editing after saving?')}</div>
        {message && <div className={css.message}>{message}</div>}
        <div className={css.actionItems}>
          <Button className={css.cancelButton} variant="outlined" onClick={() => onSaveAndClose()}>
            {t('Save and Close')}
          </Button>
          <Button className={css.proceedButton} variant="outlined" onClick={() => onSaveAndContinue()}>
            {t('Save and Continue')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default SaveAndContinueDialog;
