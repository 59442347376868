import { useDropzone } from 'react-dropzone';
import Document from 'types/Document';
import { isNullOrWhitespace } from 'utils/stringUtils';
import { Guid } from 'guid-typescript';
import DocumentComponent from 'components/common/Document';

export default function useDocumentUtils(
  onRemoveDocument: Function,
  onAddDocuments: Function,
  currentDocuments: Document[],
  newDocuments?: Document[],
  saveDocument?: Function,
  showExtensionIcon?: boolean,
  viewOnly?: boolean,
  showSnackbarMsg?: Function,
) {
  const removeDocument = (file: any, isNew: boolean) => {
    onRemoveDocument(file, isNew);
  };

  const currentFilesRender = currentDocuments?.map((file: Document) => {
    const isNew = file.isNew && !file._id;
    return (
      <DocumentComponent
        key={file._id || file.key}
        file={file}
        removeDocument={removeDocument}
        saveDocument={saveDocument}
        isNew={isNew}
        showExtensionIcon={showExtensionIcon}
        viewOnly={viewOnly}
      />
    );
  });

  const newFilesRender = newDocuments?.map((file: Document) => {
    return <DocumentComponent key={file._id || file.key} file={file} isNew={true} removeDocument={removeDocument} />;
  });

  const handleDropAccepted = (newFiles: File[]) => {
    if (newFiles.find((x) => x.size >= 18874368) === undefined) {
      const files: any = [];
      newFiles.forEach((file: File) => {
        file = Object.assign(file, { preview: URL.createObjectURL(file) });
        const document = file as any as Document;
        document.isNew = true;

        if (isNullOrWhitespace(document.name.split('.').pop() as string)) {
          document.missingExtension = true;
        }
        if (document.size === 0) {
          document.zeroBytes = true;
        }
        document.key = (Guid.create().toString() + document.name.split('.').pop()) as string;
        files.push(document);
      });
      onAddDocuments(files);
    } else {
      showSnackbarMsg && showSnackbarMsg();
    }
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open } = useDropzone({
    noKeyboard: true,
    noClick: true,
    onDropAccepted: handleDropAccepted,
  });

  return {
    getRootProps,
    getInputProps,
    currentFilesRender,
    newFilesRender,
    open,
    isDragActive,
    isDragReject,
    isDragAccept,
  };
}
