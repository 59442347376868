export function replaceInArray(array: any, value: any, index: any): any[] {
  return [...array.slice(0, index), value, ...array.slice(index + 1)];
}

export function removeFromArray(array: any, index: any): any[] {
  if (array?.length === 1) {
    return [];
  }
  return [...array.slice(0, index), ...array.slice(index + 1)];
}
