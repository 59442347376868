import { Provider } from 'react-redux';
import { store } from './store';
import ThemeCustomization from './themes';
import Equipment from 'components/container/Equipment';
import { useEffect, useState } from 'react';
import * as firebaseAnalytics from 'utils/firebaseAnalytics';
import { I18nextProvider } from 'react-i18next';
import { addEquipmentLanguageResources } from './i18n';

const Config = require('Config');

const App = (props: any) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLanguageFilesLoaded, setIsLanguageFilesLoaded] = useState(false);

  useEffect(() => {
    if (!isLanguageFilesLoaded) {
      addEquipmentLanguageResources(props.i18n);
    }

    const init = () => {
      firebaseAnalytics.init(Config.firebaseConfig);
      setIsLanguageFilesLoaded(true);
      setIsLoaded(true);
    };

    init();
  }, [props.i18n, isLanguageFilesLoaded]);

  return (
    <Provider store={store}>
      <I18nextProvider i18n={props.i18n}>
        {isLoaded && (
          <ThemeCustomization>
            <Equipment />
          </ThemeCustomization>
        )}
      </I18nextProvider>
    </Provider>
  );
};

export default App;
