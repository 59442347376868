import css from './styles/ExistingEquipmentModal.module.scss';
import { Dialog, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

interface Props {
  openModal: boolean;
  handleClose: Function;
  handleNewVin: Function;
  handleOpenEdit: Function;
}

function ExistingEquipmmentModal({ openModal, handleClose, handleNewVin, handleOpenEdit }: Props) {
  const { t } = useTranslation();

  return (
    <Dialog open={openModal} onClose={() => handleClose()} maxWidth={'sm'}>
      <div className={css.vinModal}>
        <div className={css.modalHeader}>
          <div className={css.headerText}>
            <h2>{t('Existing Equipment')}</h2>
          </div>
          <div className={css.closeIcon}>
            <CloseIcon id="xIcon" onClick={() => handleClose()}></CloseIcon>
          </div>
        </div>
        <div className={css.modalBody}>
          <p>{t('The entered VIN has already been registered')}</p>
        </div>
        <div className={css.modalFooter}>
          <div className={css.inputNewButton}>
            <Button variant="outlined" onClick={() => handleNewVin()}>
              {t('INPUT NEW VIN')}
            </Button>
          </div>
          <div className={css.editButton}>
            <Button id="buttonSearch" variant="contained" onClick={() => handleOpenEdit()}>
              {t('EDIT EXISTING EQUIPMENT')}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default ExistingEquipmmentModal;
