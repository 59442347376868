import React, { Fragment, ReactPropTypes, useEffect } from 'react';
import css from './styles/EquipmentSummary.module.scss';
import Edit from '../../assets/edit.svg';
import EditHover from '../../assets/edit-hover.svg';
import { Truck } from 'types/Truck';
import Document from 'types/Document';
import * as documentService from 'services/documentService';
import DualIconButton from 'components/common/DualIconButton';
import { compressUrlImage, validImage } from 'utils/imageUtils';
import DocumentCardList from 'components/common/DocumentCardList';
import CloseIcon from '@mui/icons-material/Close';
import classNames from 'classnames';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import LoadingSpinner from 'components/common/LoadingSpinner';
import Avatar from '@mui/material/Avatar';
import { Trailer } from 'types/Trailer';
import Switch from '@mui/material/Switch';
import * as equipmentService from 'services/equipmentService';
import { updateTruckInList, updateTrailerInList } from 'store/reducers/appSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@mui/material';
import { epochToDate } from 'utils/stringUtils';

interface Props extends Partial<ReactPropTypes> {
  handleClose: any;
  item: Truck | Trailer;
  setAddEdit: (item: Truck | Trailer) => void;
  onEdit: any;
  showSnackbar: Function;
  isOpen: boolean;
}

const EquipmentSummary: React.FC<Props> = ({ handleClose, item, onEdit, setAddEdit, showSnackbar, isOpen }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [documents, setDocuments] = React.useState<Document[]>([]);
  const [imageUrl, setImageUrl] = React.useState<string | undefined>(item.cachedImageUrl);
  const [isLoading, setIsLoading] = React.useState(false);
  const isTruck = item.equipmentType === 'truck';
  const isTrailer = item.equipmentType === 'trailer';

  const getTruckOrTrailerNumber = () => {
    if (isTruck) {
      return (item as Truck).truckNumber;
    }
    if (isTrailer) {
      return (item as Trailer).trailerNumber;
    }
  };

  const tabs = [
    { name: t('Identification'), index: 0 },
    { name: t('Owner'), index: 1 },
    { name: t('Description'), index: 2 },
    { name: t('Brand'), index: 3 },
    { name: t('Expiration Dates'), index: 3 },
    { name: t('Files'), index: 4 },
    { name: t('Comments'), index: 5 },
  ];

  const getDocuments = async () => {
    setIsLoading(true);
    const documents: Document[] = (await documentService.getDocuments(item?.transactionKey as string)).data as any;
    setDocuments(documents);
    setIsLoading(false);
  };

  const findProfileImage = async (): Promise<any> => {
    try {
      setIsLoading(true);
      const documents: Document[] = (await documentService.getDocuments(item?.profileImageKey as string)).data as any;
      if (documents.length > 0) {
        const document = documents[0];
        const image = await documentService.getPresignedUrl(document.key, document.name, 'application/octet-stream', false, false, true);

        let compressedImageUrl = await compressUrlImage(image.url, 160, 110);
        setImageUrl(compressedImageUrl as any);
        setIsLoading(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getProfileImage = async (): Promise<any> => {
    const image = await documentService.getPresignedUrl(
      item?.profileImageKey as string,
      item?.profileImageKey as string,
      'application/octet-stream',
      false,
      false,
      true,
    );
    setImageUrl(image.url);
  };

  useEffect(() => {
    if (item?.profileImageKey && !item.cachedImageUrl) {
      if (validImage(item?.profileImageKey)) {
        getProfileImage();
      } else {
        findProfileImage();
      }
    } else if (!item.cachedImageUrl) {
      setImageUrl(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.profileImageKey]);

  useEffect(() => {
    if (item?.transactionKey) {
      getDocuments();
    } else {
      setDocuments([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.transactionKey]);

  const onClickEdit = (label: string) => {
    const index = tabs?.find((tab) => tab?.name === label)?.index;
    handleClose();
    onEdit(index);
  };

  const getAddress = (item: Truck | Trailer) => {
    if (item?.address1 && item?.address2) {
      return `${item?.address1}, ${item?.address2}`;
    }
    return item?.address1;
  };

  const onSwitchChanged = async () => {
    try {
      const editedItem = { ...item };
      editedItem.isActive = !editedItem.isActive;
      if (isTruck) {
        await equipmentService.updateTruckActive(editedItem.id as number, editedItem.isActive);
        dispatch(updateTruckInList(editedItem as Truck));
      } else {
        await equipmentService.updateTrailerActive(editedItem.id as number, editedItem.isActive);
        dispatch(updateTrailerInList(editedItem as Trailer));
      }
      setAddEdit(editedItem);
    } catch (exception) {
      showSnackbar(`An error occurred while switching ${isTruck ? 'truck' : 'trailer'} state`, 'error');
    }
  };

  const stringAvatar = () => {
    if (imageUrl) {
      return {
        src: imageUrl,
        children: getTruckOrTrailerNumber(),
      };
    }
    return {
      sx: {
        bgcolor: '#C4C4C4',
      },
      children: getTruckOrTrailerNumber(),
      variant: 'rounded' as any,
    };
  };

  const blockHeader = (label: string, link = true) => {
    return (
      <div className={css.header}>
        <div className={css.label}>{label}</div>
        {link && (
          <DualIconButton className={css.iconButton} defaultImage={Edit} hoveredImage={EditHover} onClick={() => onClickEdit(label)} />
        )}
      </div>
    );
  };

  const dataList = (items: { label: string; value: any }[]) => {
    return (
      <div className={css.data}>
        {items.map((item) => {
          return (
            <div className={css.item} key={item.label}>
              <div className={css.innerLabel}>{item.label ? item.label : '--'}:</div>
              <div className={css.innerValue}>{item.value ? item.value : '--'}</div>
            </div>
          );
        })}
      </div>
    );
  };

  const block = (label: string, items: { label: string; value: any }[], link = true) => {
    return (
      <>
        {blockHeader(label, link)}
        <div className={css.block}>{dataList(items)}</div>
      </>
    );
  };

  const activeContainer = () => {
    return (
      <div className={css.activeContainer}>
        <Avatar {...stringAvatar()} />
        <div className={css.active}>
          <span className={item?.isActive ? css.dotActive : css.dotInactive}></span>
          <span className={css.activeText}>{item?.isActive ? 'Active' : 'Inactive'}</span>
        </div>
        <div className={css.switch}>
          <Switch checked={item.isActive} onChange={onSwitchChanged} value={item.isActive} name="isActive" color="primary" />
        </div>
      </div>
    );
  };

  const ownerBlock = (item: Truck | Trailer) => {
    return (
      <>
        {blockHeader(t('Owner'))}
        <div className={css.block}>
          {Boolean(item?.businessPartnerId) ? (
            dataList([
              { label: t('Full Name'), value: item?.ownerName },
              { label: t('Address'), value: getAddress(item) },
              { label: t('City'), value: item?.city },
              { label: t('State'), value: item?.state },
              { label: t('Zip Code'), value: item?.postalCode },
            ])
          ) : (
            <div className={css.companyOwned}>Company Owned</div>
          )}
        </div>
      </>
    );
  };

  const renderTruckSummary = (truck: Truck) => {
    return (
      <>
        {blockHeader(t('Identification'))}
        <div className={css.block}>
          <div className={css.id}>
            {activeContainer()}
            {dataList([{ label: t('Equipment Type'), value: t('Truck') }])}
          </div>
          {dataList([
            { label: t('Truck Number'), value: truck?.truckNumber },
            { label: t('Fuel Type'), value: truck?.fuelType?.name },
          ])}
        </div>
        <div className={css.block}>
          {dataList([
            { label: t('Plate Number'), value: truck?.licensePlateNumber },
            { label: t('Plate State'), value: truck?.licensePlateState },
            { label: t('VIN'), value: truck?.vehicleIdentificationNumber },
          ])}
        </div>
        {block(t('Description'), [
          { label: t('Color'), value: truck?.color },
          { label: t('Type'), value: truck?.truckType },
        ])}
        {block(t('Brand'), [
          { label: t('Make'), value: truck?.make },
          { label: t('Model'), value: truck?.model },
          { label: t('Year'), value: truck?.year },
        ])}
        {ownerBlock(truck)}
      </>
    );
  };

  const renderTrailerSummary = (trailer: Trailer) => {
    return (
      <>
        {blockHeader(t('Identification'))}
        <div className={css.block}>
          <div className={css.id}>
            {activeContainer()}
            {dataList([{ label: t('Equipment Type'), value: t('Trailer') }])}
          </div>
          {dataList([
            { label: t('Unit Number'), value: trailer?.trailerNumber },
            { label: t('VIN'), value: trailer?.vehicleIdentificationNumber },
            { label: t('Plate Number'), value: trailer?.licensePlateNumber },
            { label: t('Plate State'), value: trailer?.licensePlateState },
          ])}
        </div>
        {ownerBlock(trailer)}
        {block(t('Description'), [
          { label: t('Type'), value: trailer?.trailerTypeName },
          { label: t('Year'), value: trailer?.year },
          { label: t('Make'), value: trailer?.make },
          { label: t('Model'), value: trailer?.model },
          { label: t('Door Style'), value: trailer?.trailerDoorTypeName },
          { label: t('Number of Axles'), value: trailer?.numberOfAxles?.toLocaleString() },
          { label: t('Unloaded Vehicle Weight'), value: trailer?.unloadedVehicleWeight?.toLocaleString() },
          { label: t('Gross Vehicle Weight'), value: trailer?.grossVehicleWeight?.toLocaleString() },
          { label: t('Color'), value: trailer?.color },
          {
            label: t('Insurance Value'),
            value: trailer?.insuranceValue?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
          },
        ])}
        {block(t('Expiration Dates'), [
          { label: t('Dot Inspection Expiration'), value: epochToDate(trailer?.dotInspectionExpirationDate) },
          { label: t('Plates Expiration Date'), value: epochToDate(trailer?.licensePlateExpirationDate) },
          { label: t('Insurance Expiration Date'), value: epochToDate(trailer?.insuranceExpirationDate) },
        ])}
      </>
    );
  };

  return (
    <Fragment>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        PaperProps={{ sx: { borderRadius: '10px', minWidth: '830px', height: '785px', padding: '5px' } }}
        slotProps={{ backdrop: { style: { backgroundColor: '#00000050' } } }}
      >
        <DialogTitle>
          <span className={css.summaryTitle}>Equipment {getTruckOrTrailerNumber()}</span>
          <CloseIcon onClick={handleClose} className={css.closeBtn} />
        </DialogTitle>
        <DialogContent className={classNames(css.contentContainer, css.scrollable)}>
          {isLoading && <LoadingSpinner customStyles={{ marginTop: -10 }} />}
          {isTruck ? renderTruckSummary(item as Truck) : renderTrailerSummary(item as Trailer)}
          <section className={css.filesList}>
            <DocumentCardList documents={documents} handleDetailClick={() => onClickEdit(t('Files'))} />
          </section>
          <div className={css.comments}>
            <div className={css.link} onClick={() => onClickEdit(t('Comments'))}>
              {t('See all comments')}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default EquipmentSummary;
