import { FormControl, InputLabel, MenuItem, Select, FormHelperText } from '@mui/material';
import css from './styles/CustomSelect.module.scss';
import { ReactPropTypes } from 'react';

interface Props extends Partial<ReactPropTypes> {
  value: number;
  name: string;
  label: string;
  options: any[] | undefined;
  onChange: Function;
  error?: boolean;
  helperText?: string;
}

export function CustomSelect({ value, name, label, options, onChange, error, helperText }: Props) {
  return (
    <FormControl variant="filled" margin={'dense'}>
      <InputLabel classes={{ root: css.labelRoot }}>{label}</InputLabel>
      <Select name={name} error={error} value={value} onChange={(event, child) => onChange(event)} className={css.select}>
        {options?.map((item) => {
          return (
            <MenuItem classes={{ root: css.item }} key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText className={css.errorHelper}>{helperText}</FormHelperText>
    </FormControl>
  );
}

export default CustomSelect;
