import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

interface Props {
  label: string;
  value: any;
  onChange: any;
  validation?: any;
}

export default function CustomDatePicker({ label, value, onChange, validation }: Props) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        value={value || null}
        onChange={(newValue) => onChange(newValue)}
        renderInput={(props) => (
          <TextField
            {...props}
            label={label}
            value={value}
            variant={'filled'}
            error={validation.hasError}
            helperText={validation.message}
          />
        )}
        InputAdornmentProps={{ sx: { paddingRight: '15px' } }}
        InputProps={{ sx: { marginTop: '8px', marginBottom: '4px' } }}
      />
    </LocalizationProvider>
  );
}
