import LoadingSpinner from 'components/common/LoadingSpinner';
import { useAppDispatch, useAppSelector } from '../../store';
import css from './styles/Equipment.module.scss';
import {
  getLoading,
  getShowSnackbar,
  getSnackbarConfig,
  getTrailerList,
  getTrailers,
  getTruckList,
  getTrucks,
  initData,
  setAndShowSnackbar,
  setShowSnackbar,
} from 'store/reducers/appSlice';
import { useEffect, useState } from 'react';
import { CloverThemeProvider, Snackbar } from 'shamrock-clover-ui';
import Portal from '@mui/material/Portal';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import ExistingEquipmmentModal from 'components/common/ExistingEquipmentModal';
import NavLinkBar from 'components/common/NavLinkBar';
import { Truck } from 'types/Truck';
import FilterChipBar from 'components/common/FilterChipBar';
import EquipmentSummary from 'components/common/EquipmentSummary';
import EquipmentAddEdit from 'components/trucks/EquipmentAddEdit';
import VinModal from './VinModal';
import SearchInput from 'components/common/SearchInput';
import { Trailer } from 'types/Trailer';
import EquipmentCard from 'components/common/EquipmentCard';
import { FuelType } from 'types/FuelType';
import { VinDecoderResponse } from 'types/Vin';
import FirebaseAnalytics from 'utils/firebaseAnalytics';
import { concat } from 'lodash';

const Equipment = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getLoading);
  const trucks = useAppSelector(getTruckList);
  const trailers = useAppSelector(getTrailerList);
  const snackbarConfig = useAppSelector(getSnackbarConfig);
  const showSnackbar = useAppSelector(getShowSnackbar);
  const [tabIndex, setTabIndex] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [truckFilter, setTruckFilter] = useState('all');
  const [displayedTrucks, setDisplayedTrucks] = useState(trucks);
  const [isEquipmentSummaryOpen, setIsEquipmentSummaryOpen] = useState(false);
  const [equipmentAddEdit, setEquipmentAddEdit] = useState<Truck | Trailer | undefined>(undefined);
  const [isNew, setIsNew] = useState(true);
  const [isEquipmentDialogOpen, setIsEquipmentDialogOpen] = useState(false);
  const [equipmentEditIndex, setEquipmentEditIndex] = useState(-1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [previousCreatedTruck, setPreviousTruck] = useState<Truck | undefined>(undefined);
  const [trailerFilter, setTrailerFilter] = useState('all');
  const [displayedTrailers, setDisplayedTrailers] = useState(trailers);
  const barItems = [
    { title: t('Trucks'), index: 0 },
    { title: t('Trailers'), index: 1 },
  ];

  const [openVinModal, setOpenVinModal] = useState(false);
  const [openExistingEquipmentModal, setOpenExistingEquipmentModal] = useState(false);
  const [vinSearchData, setVinSearchData] = useState<VinDecoderResponse | undefined>(undefined);
  const [vinSearchUsed, setVinSearchUsed] = useState(false);
  const usedVins = concat(
    trucks?.filter((t) => t.vehicleIdentificationNumber !== null).map((item) => item.vehicleIdentificationNumber),
    trailers?.filter((t) => t.vehicleIdentificationNumber !== null).map((item) => item.vehicleIdentificationNumber),
  ).filter((vin) => vin !== undefined && vin !== '');
  const [searchedDuplicateVin, setSearchedDuplicateVin] = useState('');

  useEffect(() => {
    if (vinSearchData !== undefined) {
      handleAddEquipmentClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vinSearchData, vinSearchUsed]);

  useEffect(() => {
    dispatch(initData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tabIndex === 0) {
      const filteredTrucks =
        truckFilter !== 'all'
          ? truckFilter === 'active'
            ? trucks?.filter((t) => t.isActive)
            : trucks?.filter((t) => !t.isActive)
          : trucks;
      if (searchText.length) {
        const inNumber = (t: Truck) => t.truckNumber?.toLowerCase().includes(searchText.toLowerCase());
        const inFuel = (t: Truck) => t.fuelType?.name?.toLowerCase().includes(searchText.toLowerCase());
        const inPlate = (t: Truck) => t.licensePlateNumber?.toLowerCase().includes(searchText.toLowerCase());
        const inState = (t: Truck) => t.licensePlateState?.toLowerCase().includes(searchText.toLowerCase());
        const searchedTrucks = filteredTrucks?.reduce((list: Truck[], truck: Truck) => {
          if (inNumber(truck) || inFuel(truck) || inPlate(truck) || inState(truck)) {
            return [...list, truck];
          }
          return list;
        }, []);
        setDisplayedTrucks(searchedTrucks);
      } else {
        setDisplayedTrucks(filteredTrucks);
      }
    }
  }, [trucks, truckFilter, searchText, tabIndex]);

  useEffect(() => {
    if (tabIndex === 1) {
      const filteredTrailers =
        trailerFilter !== 'all'
          ? trailerFilter === 'active'
            ? trailers?.filter((t) => t.isActive)
            : trailers?.filter((t) => !t.isActive)
          : trailers;
      if (searchText.length) {
        // TODO: Adjust these and the Trailer model to final trailer attributes
        const inNumber = (t: Trailer) => t.trailerNumber?.toLowerCase().includes(searchText.toLowerCase());
        const inType = (t: Trailer) => t.trailerTypeName?.toLowerCase().includes(searchText.toLowerCase());
        const inPlate = (t: Trailer) => t.licensePlateNumber?.toLowerCase().includes(searchText.toLowerCase());
        const inState = (t: Trailer) => t.licensePlateState?.toLowerCase().includes(searchText.toLowerCase());
        const searchedTrailers = filteredTrailers?.reduce((list: Trailer[], trailer: Trailer) => {
          if (inNumber(trailer) || inType(trailer) || inPlate(trailer) || inState(trailer)) {
            return [...list, trailer];
          }
          return list;
        }, []);
        setDisplayedTrailers(searchedTrailers);
      } else {
        setDisplayedTrailers(filteredTrailers);
      }
    }
  }, [trailers, trailerFilter, searchText, tabIndex]);

  const handleSetCurrentTab = async (tabIndex: number) => {
    if (tabIndex === 1) {
      dispatch(getTrailers());
      setTruckFilter('all');
    } else {
      dispatch(getTrucks());
      setTrailerFilter('all');
    }
    setTabIndex(tabIndex);
    onClear();
  };

  const handleEquipmentCardClick = (equipment: Truck | Trailer) => {
    setEquipmentAddEdit(equipment);
    setIsEquipmentSummaryOpen(true);
  };

  const handleEquipmentEditClick = (equipment: Truck | Trailer, index: number) => {
    setEquipmentEditIndex(index);
    setIsNew(false);
    setEquipmentAddEdit(equipment);
    setIsEquipmentDialogOpen(true);
  };

  const handleAddEquipmentClick = () => {
    setEquipmentEditIndex(0);
    setIsNew(true);
    const fuelType: FuelType = { id: 1 };
    if (vinSearchUsed && vinSearchData !== undefined) {
      const equipment = mapVinSearchDecode();
      const isTruck = vinSearchData?.equipmentType === 'TRUCK';
      handleAddEditOpenModalAnalytics(isTruck);
      setEquipmentAddEdit({
        equipmentType: isTruck ? 'truck' : 'trailer',
        fuelType: fuelType,
        isActive: true,
        make: equipment?.make,
        model: equipment?.model,
        year: equipment?.year,
        numberOfAxles: equipment?.numberOfAxles,
        unloadedVehicleWeight: equipment?.unloadedVehicleWeight,
        vehicleIdentificationNumber: equipment?.vehicleIdentificationNumber,
      });
    } else {
      handleAddEditOpenModalAnalytics(true);
      setEquipmentAddEdit({ equipmentType: 'truck', fuelType: fuelType, isActive: true });
    }
    setIsEquipmentDialogOpen(true);
  };

  const onClear = () => {
    setSearchText('');
  };

  const mapVinSearchDecode = () => {
    const Equipment: Truck | Trailer = {
      make: vinSearchData?.make,
      model: vinSearchData?.model,
      year: vinSearchData?.year,
      numberOfAxles: vinSearchData?.axles,
      unloadedVehicleWeight: vinSearchData?.unloadedVehicleWeight,
      vehicleIdentificationNumber: vinSearchData?.vin,
    };
    return Equipment;
  };

  const handleAddEditOpenVinDecode = () => {
    setIsEquipmentDialogOpen(false);
    setOpenVinModal(true);
  };

  const handleAddEditOpenModalAnalytics = (isTruck: boolean) => {
    FirebaseAnalytics.sendEvent(
      FirebaseAnalytics.EVENTS.SELECT,
      FirebaseAnalytics.MODULES.ENTERPRISE,
      FirebaseAnalytics.PAGES.ADD_EQUIPMENT,
      {
        label: isTruck ? FirebaseAnalytics.LABELS.TRUCK : FirebaseAnalytics.LABELS.TRAILER,
      },
    );
  };

  const handleDuplicateVin = (input: string) => {
    setSearchedDuplicateVin(input);
    setOpenVinModal(false);
    setOpenExistingEquipmentModal(true);
  };

  const handleExistingVinOpenEdit = () => {
    const searchedTrailers = trailers?.find((t) => t.vehicleIdentificationNumber === searchedDuplicateVin);
    const searchedTrucks = trucks?.find((t) => t.vehicleIdentificationNumber === searchedDuplicateVin);
    const equipment = searchedTrailers || searchedTrucks;
    if (equipment) {
      setOpenExistingEquipmentModal(false);
      setIsNew(false);
      setEquipmentEditIndex(0);
      setEquipmentAddEdit(equipment);
      setIsEquipmentDialogOpen(true);
    }
  };

  return (
    <div className={css.container}>
      {isLoading && <LoadingSpinner customStyles={{ backgroundColor: '#888888' }} />}
      <Typography variant="h4" fontStyle={{ marginBottom: '30px' }}>
        Equipment
      </Typography>
      <div className={css.button}>
        <Button id={css.createButton} color="primary" size="medium" variant="outlined" onClick={() => setOpenVinModal(true)}>
          {' '}
          <AddCircleIcon className={css.icon} />
          {t('Add New')}
        </Button>
        {openVinModal && (
          <VinModal
            openModal={openVinModal}
            handleClose={() => setOpenVinModal(false)}
            handleAddEquipment={handleAddEquipmentClick}
            setVinSearchData={setVinSearchData}
            setVinSearchUsed={setVinSearchUsed}
            vinsUsed={usedVins}
            handleDuplicateVin={(duplicate: string) => handleDuplicateVin(duplicate)}
          />
        )}
        <ExistingEquipmmentModal
          openModal={openExistingEquipmentModal}
          handleClose={() => setOpenExistingEquipmentModal(false)}
          handleOpenEdit={() => handleExistingVinOpenEdit()}
          handleNewVin={() => {
            setOpenExistingEquipmentModal(false);
            setOpenVinModal(true);
          }}
        />
        <SearchInput
          placeHolder="Input search text"
          value={searchText}
          setValue={(value: string) => setSearchText(value)}
          onClear={onClear}
        />
      </div>
      <div className={css.tabBarContainer}>
        <NavLinkBar items={barItems} currentIndex={tabIndex} handleClick={(index: number) => handleSetCurrentTab(index)} />
      </div>
      <div className={css.filterBarContainer}>
        <FilterChipBar
          type={tabIndex === 0 ? 'truck' : 'trailer'}
          allItemCount={tabIndex === 0 ? trucks?.length : trailers?.length}
          activeItemCount={tabIndex === 0 ? trucks?.filter((d) => d.isActive).length : trailers?.filter((d) => d.isActive).length}
          inactiveItemCount={tabIndex === 0 ? trucks?.filter((d) => !d.isActive).length : trailers?.filter((d) => !d.isActive).length}
          filter={tabIndex === 0 ? (status: string) => setTruckFilter(status) : (status: string) => setTrailerFilter(status)}
        />
      </div>
      <div hidden={tabIndex !== 0} className={tabIndex === 0 ? css.gridWrapper : undefined}>
        <div className={css.equipmentGrid}>
          {displayedTrucks &&
            displayedTrucks.length > 0 &&
            displayedTrucks.map((truck: Truck, index) => (
              <EquipmentCard
                key={truck.id}
                index={index}
                item={truck}
                onClick={() => handleEquipmentCardClick(truck)}
                showSnackbar={(message: string, type: any) => dispatch(setAndShowSnackbar({ message, type }))}
              />
            ))}
          {!trucks ||
            (trucks.length < 1 && !isLoading && (
              <div>
                <h6>{t('No trucks entered')}</h6>
              </div>
            ))}
        </div>
        {isEquipmentSummaryOpen && (
          <EquipmentSummary
            handleClose={() => setIsEquipmentSummaryOpen(false)}
            item={equipmentAddEdit as Truck}
            setAddEdit={(truck) => setEquipmentAddEdit(truck as Truck)}
            onEdit={(index: number) => handleEquipmentEditClick(equipmentAddEdit as Truck, index)}
            showSnackbar={(message: string, type: any) => dispatch(setAndShowSnackbar({ message, type }))}
            isOpen={isEquipmentSummaryOpen}
          />
        )}
        {isEquipmentDialogOpen && (
          <div className={css.truckEdit}>
            <EquipmentAddEdit
              equipment={equipmentAddEdit as Truck}
              isOpen={isEquipmentDialogOpen}
              handleClose={(createdTruck: Truck | undefined = undefined) => {
                setIsEquipmentDialogOpen(false);
                setVinSearchData(undefined);
                setVinSearchUsed(false);
                setEquipmentEditIndex(-1);
                setPreviousTruck(isNew ? createdTruck : undefined);
              }}
              isNew={isNew}
              trucks={trucks}
              activeIndex={equipmentEditIndex}
              setEquipmentStatus={(isNew: boolean) => setIsNew(isNew)}
              showSnackbar={(message: string, type: any) => dispatch(setAndShowSnackbar({ message, type }))}
              handleOpenVinDecode={handleAddEditOpenVinDecode}
              usedVinDecode={vinSearchUsed}
              handleOnNewSave={(equipment: Truck) => setEquipmentAddEdit(equipment)}
              usedVins={usedVins}
              onSetVinSearchUsed={(isUsed: boolean) => setVinSearchUsed(isUsed)}
            />
          </div>
        )}
      </div>
      <div hidden={tabIndex !== 1} className={tabIndex === 1 ? css.gridWrapper : undefined}>
        <div className={css.equipmentGrid}>
          {displayedTrailers &&
            displayedTrailers.length > 0 &&
            displayedTrailers.map((trailer: Trailer, index) => (
              <EquipmentCard
                key={trailer.id}
                index={index}
                item={trailer}
                onClick={() => handleEquipmentCardClick(trailer)}
                showSnackbar={(message: string, type: any) => dispatch(setAndShowSnackbar({ message, type }))}
              />
            ))}
          {!trailers ||
            (trailers.length < 1 && !isLoading && (
              <div>
                <h6>{t('No trailers entered')}</h6>
              </div>
            ))}
        </div>
        {isEquipmentSummaryOpen && (
          <EquipmentSummary
            handleClose={() => setIsEquipmentSummaryOpen(false)}
            isOpen={isEquipmentSummaryOpen}
            item={equipmentAddEdit as Trailer}
            setAddEdit={(trailer) => setEquipmentAddEdit(trailer as Trailer)}
            onEdit={(index: number) => handleEquipmentEditClick(equipmentAddEdit as Trailer, index)}
            showSnackbar={(message: string, type: any) => dispatch(setAndShowSnackbar({ message, type }))}
          />
        )}
        {isEquipmentDialogOpen && (
          <div className={css.truckEdit}>
            <EquipmentAddEdit
              equipment={equipmentAddEdit as Trailer}
              isOpen={isEquipmentDialogOpen}
              handleClose={(createdTrailer: Trailer | undefined = undefined) => {
                setIsEquipmentDialogOpen(false);
                setVinSearchData(undefined);
                setVinSearchUsed(false);
                setEquipmentEditIndex(-1);
                setPreviousTruck(isNew ? createdTrailer : undefined);
              }}
              isNew={isNew}
              trailers={trailers}
              activeIndex={equipmentEditIndex}
              setEquipmentStatus={(isNew: boolean) => setIsNew(isNew)}
              showSnackbar={(message: string, type: any) => dispatch(setAndShowSnackbar({ message, type }))}
              handleOpenVinDecode={handleAddEditOpenVinDecode}
              usedVinDecode={vinSearchUsed}
              handleOnNewSave={(equipment: Trailer) => setEquipmentAddEdit(equipment)}
              usedVins={usedVins}
              onSetVinSearchUsed={(isUsed: boolean) => setVinSearchUsed(isUsed)}
            />
          </div>
        )}
      </div>
      <Portal>
        <CloverThemeProvider>
          <Snackbar
            width="1000px"
            duration={snackbarConfig.time}
            open={showSnackbar}
            position="bottomCenter"
            message={snackbarConfig.message}
            variant={snackbarConfig.type}
            onClose={() => dispatch(setShowSnackbar(false))}
            buttonTitle={''}
            buttonOnClick={undefined}
          />
        </CloverThemeProvider>
      </Portal>
    </div>
  );
};

export default Equipment;
