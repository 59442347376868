import { isNullOrWhitespace } from './stringUtils';
import { Truck } from 'types/Truck';
import { BusinessPartner } from 'types/BusinessPartner';
import { Trailer } from 'types/Trailer';

export function validatePartner(partner: BusinessPartner) {
  let result = {
    valid: true,
    missingFirstName: false,
    missingPhoneNumber: false,
    invalidEmergencyPhoneNumber: false,
    invalidZipCode: false,
  };
  const { phoneNumber, firstName } = partner;
  result.missingFirstName = isNullOrWhitespace(firstName);
  result.missingPhoneNumber = isNullOrWhitespace(phoneNumber) || phoneNumber.length !== 10;

  if (partner.postalCode) {
    const reg = new RegExp(/^\d{5}(?:[-\s]\d{4})?$/);
    result.invalidZipCode = !reg.test(partner.postalCode);
  }

  result.valid = !result.missingPhoneNumber && !result.missingFirstName && !result.invalidEmergencyPhoneNumber && !result.invalidZipCode;
  return result;
}

export function validateTruck(truck: Truck) {
  const result = {
    valid: true,
    missingNumber: false,
    existingNumber: false,
    invalidVehicleIdentificationNumber: false,
    existingVehicleIdentificationNumber: false,
  };
  if (truck.vehicleIdentificationNumber) {
    const reg = new RegExp(/^[A-HJ-NPR-Z0-9]{17}$/);
    result.invalidVehicleIdentificationNumber = !reg.test(truck.vehicleIdentificationNumber);
  }
  result.missingNumber = isNullOrWhitespace(truck?.truckNumber as string);
  result.valid = !result.missingNumber && !result.invalidVehicleIdentificationNumber;
  return result;
}

export function validateTrailer(trailer: Trailer) {
  let result = {
    valid: true,
    missingNumber: false,
    existingNumber: false,
    invalidVehicleIdentificationNumber: false,
    existingVehicleIdentificationNumber: false,
  };
  if (trailer.vehicleIdentificationNumber) {
    const reg = new RegExp(/^[A-HJ-NPR-Z0-9]{17}$/);
    result.invalidVehicleIdentificationNumber = !reg.test(trailer.vehicleIdentificationNumber);
  }
  result.missingNumber = isNullOrWhitespace(trailer?.trailerNumber as string);
  result.valid = !result.missingNumber && !result.invalidVehicleIdentificationNumber;
  return result;
}
