import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Guid } from 'guid-typescript';
import { Avatar } from '@mui/material';
import { Button, CloverThemeProvider } from 'shamrock-clover-ui';
import { Dialog } from 'shamrock-clover-ui/dist/clover/components/Dialog/Dialog';
import Document from 'types/Document';
import DocumentUpload from './DocumentUpload';
import LoadingSpinner from './LoadingSpinner';
import UnsavedChangesDialog from './UnsavedChangesDialog';
import * as documentService from 'services/documentService';
import { resizeImageFile, validImage } from 'utils/imageUtils';
import { isNullOrWhitespace } from 'utils/stringUtils';
import css from './styles/ImageUploadDialog.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  handleClose: any;
  imageUrl?: string;
  deleteImage: any;
  saveImage: any;
  setDocument: any;
  showSnackBar: any;
}

const ImageUploadDialog: React.FC<Props> = ({
  isOpen,
  handleClose,
  imageUrl,
  deleteImage,
  saveImage,
  setDocument,
  showSnackBar,
}: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [file, setFile] = React.useState<Document>();
  const [openUnsavedDialog, setOpenUnsavedDialog] = React.useState(false);

  const onDelete = async () => {
    setLoading(true);
    await deleteImage();
    setFile(undefined);
    setLoading(false);
  };

  const onSave = async () => {
    setLoading(true);
    await saveImage();
    setFile(undefined);
    setLoading(false);
  };

  const handleOnClose = () => {
    if (file) {
      setOpenUnsavedDialog(true);
    } else {
      handleClose();
    }
  };

  React.useEffect(() => {
    if (file) {
      const uploadToStage = async () => {
        const callback = (progress: ProgressEvent): void => {
          if (progress.loaded === progress.total) {
            setLoading(false);
          }
        };
        if (file.zeroBytes) {
          setLoading(false);
        } else {
          setLoading(true);
          const options = { name: file['name'], getUrl: true };
          await documentService.uploadToStage(file['key'], file['type'] as any, file, callback, options);
          setDocument(file);
        }
      };
      if (!file.missingExtension) {
        uploadToStage();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const handleDropAccepted = async (newFiles: File[]) => {
    const files = [];
    let errorMessage = undefined;

    let file = await resizeImageFile(newFiles[0], 1024, 1024);
    file = Object.assign(file, { preview: URL.createObjectURL(file) });
    const document = file as any as Document;
    document.isNew = true;

    if (isNullOrWhitespace(document.name.split('.').pop() as string)) {
      document.missingExtension = true;
      errorMessage = t('File must have an extension.');
    }

    if (document.size === 0) {
      document.zeroBytes = true;
      errorMessage = t('File size must be greater than 0.');
    }

    if (!validImage(document)) {
      errorMessage = t('File must be a valid image.');
    }
    document.key = Guid.create().toString() + document.name.split('.').pop();
    files.push(document);

    if (files.length > 0 && errorMessage === undefined) {
      setFile(files[0]);
    }

    if (errorMessage) {
      showSnackBar(errorMessage, 'error');
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noKeyboard: true,
    noClick: true,
    multiple: false,
    onDropAccepted: handleDropAccepted,
  });

  const modalContent = (
    <div className={css.container}>
      <div className={css.modalHeading}>Photo Detail</div>
      {loading && <LoadingSpinner customStyles={{ position: 'static', height: 'calc(100% - 100px' }} />}
      <div className={css.imageUploadContainer}>
        {imageUrl && !loading && (
          <div className={css.modalImageContainer}>
            <Avatar src={imageUrl} variant="rounded" className={css.modalImage} />
          </div>
        )}
        {!imageUrl && !loading && (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <DocumentUpload open={open} isLoading={loading} />
          </div>
        )}
      </div>
      <UnsavedChangesDialog
        open={openUnsavedDialog || false}
        onDialogClose={() => {
          setOpenUnsavedDialog(false);
          setFile(undefined);
          setDocument(undefined);
          handleClose();
        }}
        onPrimaryActionCompleted={() => {
          setFile(undefined);
          setDocument(undefined);
          setOpenUnsavedDialog(false);
          handleClose();
        }}
      />
    </div>
  );

  const modalActions = (
    <div className={css.actions}>
      <Button buttonStyle="text" color="red" disabled={!imageUrl} onClick={onDelete}>
        {t('Delete')}
      </Button>
      <Button disabled={!file} onClick={onSave}>
        {t('Upload Photo')}
      </Button>
    </div>
  );

  return (
    <div className={css.dialog}>
      <CloverThemeProvider>
        <Dialog open={isOpen} content={modalContent} actions={modalActions} onClose={handleOnClose} closeOnBackdropClick />
      </CloverThemeProvider>
    </div>
  );
};

export default ImageUploadDialog;
