import axios, { AxiosResponse } from 'axios';
import { ApiError } from 'types/ApiError';
import { BusinessPartner } from 'types/BusinessPartner';
import { Country } from 'types/Country';
import { Driver } from 'types/Driver';
import { EquipmentMake } from 'types/EquipmentMake';
import { EquipmentModel } from 'types/EquipmentModel';
import { FuelEntry } from 'types/FuelEntry';
import { Note } from 'types/Note';
import { TmsCompany } from 'types/TmsCompany';
import { Trailer } from 'types/Trailer';
import { TrailerDoorType } from 'types/TrailerDoorType';
import { TrailerType } from 'types/TrailerType';
import { Truck } from 'types/Truck';
import { VinDecoderResponse } from 'types/Vin';
import http, { getToken } from 'utils/axiosUtils';
import { getCompanyId } from 'utils/localAuthUtils';
import uuid4 from 'uuid4';

// TODO: Replace most of these with endpoints from the eventual Equipment Service
const Config = require('Config');
const { loadManagementServiceUrl } = Config;
const { equipmentServiceUrl } = Config;

//VIN Decoding
export const checkVin = async (vinInput?: string) => {
  try {
    const token = await getToken();
    const response: AxiosResponse<VinDecoderResponse> = await axios.post(
      `${equipmentServiceUrl}equipment`,
      { vin: vinInput },
      {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
      },
    );
    return response.data;
  } catch (error: any) {
    const apiError: ApiError = {
      status: error.response.status,
      statusText: error.response.statusText,
      data: error.response.data,
    };
    throw apiError;
  }
};

// General

export const getCountries = () => http.get<Country[], unknown>(`${loadManagementServiceUrl}country`)();

export const getFuelTypes = () => http.get<FuelEntry[], unknown>(`${loadManagementServiceUrl}fuellogs/fueltypes/`)();

export const getTmsCompany = async () => http.get<TmsCompany, unknown>(`${loadManagementServiceUrl}company/crm/${await getCompanyId()}`)();

// Trucks

export const getTrucks = (activeOnly?: boolean) => {
  let url = `${loadManagementServiceUrl}trucks`;
  if (activeOnly) {
    url = `${loadManagementServiceUrl}trucks/?isActive=true`;
  }
  return http.get<Truck[], unknown>(url)();
};

export const getTruck = (id?: number) => http.get<Truck, unknown>(`${loadManagementServiceUrl}trucks/${id}`)();

export const canDeleteTruck = (id: number) => http.get<boolean, unknown>(`${loadManagementServiceUrl}trucks/${id}/candelete`)();

export const deleteTruck = (id?: number) => http.delete<number, unknown>(`${loadManagementServiceUrl}trucks/${id}`)();

export const saveTruck = async (truck?: Truck) => {
  if (truck?.id) {
    return updateTruck(truck);
  }
  return createTruck(truck);
};

export const createTruck = async (truck?: Truck) => {
  try {
    const token = await getToken();
    const response: AxiosResponse = await axios.post(`${loadManagementServiceUrl}trucks`, truck, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error: any) {
    const apiError: ApiError = {
      status: error.response.status,
      statusText: error.response.statusText,
      data: error.response.data,
    };
    throw apiError;
  }
};

export const updateTruck = async (truck?: Truck) => {
  try {
    const token = await getToken();
    const response: AxiosResponse = await axios.patch(
      `${loadManagementServiceUrl}trucks`,
      truck?.transactionKey ? truck : { ...truck, transactionKey: uuid4() },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return response;
  } catch (error: any) {
    const apiError: ApiError = {
      status: error.response.status,
      statusText: error.response.statusText,
      data: error.response.data,
    };
    throw apiError;
  }
};

export const updateTruckActive = (truckId: number, isActive: boolean) =>
  http.patch<Note[], unknown>(`${loadManagementServiceUrl}trucks/${truckId}/isactive/${isActive}`)();

export const getTruckNotes = (id: number) => http.get<Note[], unknown>(`${loadManagementServiceUrl}trucks/${id}/notes`)();

export const addTruckNote = (note: Note, truckId: number) =>
  http.post<Note[], unknown>(`${loadManagementServiceUrl}trucks/${truckId}/notes`)(note);

export const editTruckNote = (noteId: number, truckId: number, message: string) =>
  http.patch<Note[], unknown>(`${loadManagementServiceUrl}trucks/${truckId}/notes/${noteId}`)({ note: message });

export const deleteTruckNote = (noteId: number, truckId: number) =>
  http.delete<number, unknown>(`${loadManagementServiceUrl}trucks/${truckId}/notes/${noteId}`)();

// Trailers
export const getTrailerTypes = () => http.get<TrailerType[], unknown>(`${equipmentServiceUrl}trailers/types`)();

export const getTrailers = (activeOnly?: boolean) => {
  let url = `${equipmentServiceUrl}trailers`;
  if (activeOnly) {
    url = `${equipmentServiceUrl}trailers/?isActive=true`;
  }
  return http.get<Trailer[], unknown>(url)();
};

export const getTrailer = (id?: number) => http.get<Trailer, unknown>(`${equipmentServiceUrl}trailers/${id}`)();

export const canDeleteTrailer = (id: number) => http.get<boolean, unknown>(`${equipmentServiceUrl}trailers/${id}/candelete`)();

export const deleteTrailer = (id?: number) => http.delete<number, unknown>(`${equipmentServiceUrl}trailers/${id}`)();

export const saveTrailer = async (trailer?: Trailer) => {
  if (trailer?.id) {
    return updateTrailer(trailer);
  }
  return createTrailer(trailer);
};

export const createTrailer = async (trailer?: Trailer) => {
  try {
    const token = await getToken();
    const response: AxiosResponse = await axios.post(`${equipmentServiceUrl}trailers`, trailer, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error: any) {
    const apiError: ApiError = {
      status: error.response.status,
      statusText: error.response.statusText,
      data: error.response.data,
    };
    throw apiError;
  }
};

export const updateTrailer = async (trailer?: Trailer) => {
  try {
    const token = await getToken();
    const response: AxiosResponse = await axios.put(
      `${equipmentServiceUrl}trailers`,
      trailer?.transactionKey ? trailer : { ...trailer, transactionKey: uuid4() },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return response;
  } catch (error: any) {
    const apiError: ApiError = {
      status: error.response.status,
      statusText: error.response.statusText,
      data: error.response.data,
    };
    throw apiError;
  }
};

export const updateTrailerActive = (trailerId: number, isActive: boolean) =>
  http.patch<Note[], unknown>(`${equipmentServiceUrl}trailers/${trailerId}/isactive/${isActive}`)();

export const getTrailerNotes = (id: number) => http.get<Note[], unknown>(`${equipmentServiceUrl}trailers/${id}/notes`)();

export const addTrailerNote = (note: Note, trailerId: number) =>
  http.post<Note[], unknown>(`${equipmentServiceUrl}trailers/${trailerId}/notes`)(note);

export const editTrailerNote = (noteId: number, trailerId: number, message: string) =>
  http.patch<Note[], unknown>(`${equipmentServiceUrl}trailers/${trailerId}/notes/${noteId}`)({ note: message });

export const deleteTrailerNote = (noteId: number, trailerId: number) =>
  http.delete<number, unknown>(`${equipmentServiceUrl}trailers/${trailerId}/notes/${noteId}`)();

export const getTrailerDoorTypes = () => http.get<TrailerDoorType[], unknown>(`${equipmentServiceUrl}trailers/door_types`)();

// Business Partners and Drivers

export const getBusinessPartners = () => http.get<BusinessPartner[], unknown>(`${loadManagementServiceUrl}businesspartners`)();

export const getBusinessPartner = (id: number) => http.get<BusinessPartner, unknown>(`${loadManagementServiceUrl}businesspartners/${id}`)();

export const saveDriver = async (driver: Driver | BusinessPartner, isNew: boolean): Promise<any> => {
  let response: AxiosResponse;
  const token = await getToken();
  try {
    if (isNew) {
      response = await axios.post(`${loadManagementServiceUrl}drivers`, driver, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } else {
      response = await axios.patch(`${loadManagementServiceUrl}drivers`, driver, {
        headers: { Authorization: `Bearer ${token}` },
      });
    }
    if (response.status > 299) {
      throw response;
    }
    return response.data;
  } catch (error: any) {
    throw error.response;
  }
};

// Equipment

export const getEquipmentMakes = (vehicleType: 'truck' | 'trailer') =>
  http.get<EquipmentMake[], unknown>(`${equipmentServiceUrl}equipment/${vehicleType}/make`)();
export const getEquipmentModels = (vehicleType: 'truck' | 'trailer', makeId: number | undefined) =>
  http.get<EquipmentModel[], unknown>(`${equipmentServiceUrl}equipment/${vehicleType}/make/${makeId}/model`)();
