import axios, { AxiosResponse } from 'axios';
import { UploadMetaData } from 'types/UploadMetaData';
import Document from 'types/Document';
import http, { getDefaultConfig, getToken } from 'utils/axiosUtils';
import { getCompanyId } from 'utils/localAuthUtils';
const mime = require('mime');

const Config = require('Config');
const { documentsServiceUrl } = Config;

interface DocumentSaveResponse {
  completedFiles: Document[];
  failedFiles: Document[];
}

interface PreSignedUrl {
  url: string;
}

interface DocumentDeleteResponse {
  ok: number;
}

const mapFilesToDocumentRequest = (files: Document[], transactionId?: string, companyId?: any, destinationBucket?: string): any => {
  const fileData = files.map((file: Document): any => {
    return {
      _id: file._id || null,
      name: file.name,
      key: file.key,
    };
  });
  return {
    transactionId: transactionId,
    files: fileData,
    companyId: companyId,
    destination: destinationBucket || undefined,
  };
};

export const getDocuments = async (documentKey: string) =>
  http.get<any[], unknown>(`${documentsServiceUrl}file/${await getCompanyId()}?transactionId=${documentKey}`)();

export const uploadFile = async (fileToUpload: any, url: string, onUploadProgress: (progressEvent: any) => any): Promise<any> => {
  const headers = {
    'Content-Type': fileToUpload.type,
  };
  const options = {
    headers: headers,
    onUploadProgress: onUploadProgress,
  };

  const response: AxiosResponse = await axios.put(url, fileToUpload, options);

  if (response.status !== 200) {
    throw new Error();
  }
  return response.data;
};

export const saveFileMetaData = async (
  metaData: UploadMetaData,
  onUploadProgress?: (progressEvent: any) => any,
): Promise<DocumentSaveResponse> => {
  const options = {
    onUploadProgress: onUploadProgress,
    headers: {
      Authorization: `Bearer ${await getToken()}`,
    },
  };

  const response: AxiosResponse = await axios.post(`${documentsServiceUrl}file/`, metaData, options);

  if (response.status !== 200) {
    throw new Error();
  }
  return response.data;
};

export const getPresignedUrl = async (
  key: string,
  name: string,
  type: string,
  isUpload: boolean,
  isStaged: boolean,
  isView?: boolean,
  bucket?: any,
): Promise<PreSignedUrl> => {
  const mimeType = mime.getType(name);
  const body = {
    key: key,
    type: mimeType || type,
    isUpload: isUpload,
    isStaged: isStaged,
    view: isView,
    fileName: name,
    bucket: bucket,
  };

  const response: AxiosResponse = await axios.post(`${documentsServiceUrl}file/generatepresignedurl`, JSON.stringify(body), {
    ...(await getDefaultConfig()),
  });

  if (response.status !== 200) {
    throw Error();
  }
  return response.data;
};

export const deleteMongoDocuments = async (metaData: UploadMetaData): Promise<DocumentDeleteResponse> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${await getToken()}`,
  };

  const options = {
    headers: headers,
    data: metaData,
  };

  const response: AxiosResponse = await axios.delete(`${documentsServiceUrl}file/`, options as any);

  if (response.status !== 200) {
    throw Error();
  }
  return response.data;
};

export const uploadToStage = async (
  key: string,
  mimeType: string,
  file: any,
  progressUpload?: any,
  options?: any,
): Promise<string | undefined> => {
  const { name, getUrl } = options;
  let url = await getPresignedUrl(key, name, mimeType, true, false);
  await uploadFile(file, url.url, progressUpload);
  if (getUrl) {
    url = await getPresignedUrl(key, name, 'application/octet-stream', false, true);
    return url.url;
  } else {
    return;
  }
};

export const save = async (
  files: Document[],
  transactionId: string,
  destinationBucket?: string,
  progressUpload?: any,
): Promise<DocumentSaveResponse> => {
  const metadata = mapFilesToDocumentRequest(files, transactionId, await getCompanyId(), destinationBucket);
  return await saveFileMetaData(metadata, progressUpload);
};

export const uploadAndSave = async (
  key: string,
  mimeType: string,
  file: any,
  progressUpload?: any,
  options?: any,
): Promise<string | undefined> => {
  const { transactionId, destinationBucket, getUrl, name } = options;
  await uploadToStage(key, mimeType, file, progressUpload, options);
  await save([file], transactionId, destinationBucket, progressUpload);
  if (getUrl) {
    const url = await getPresignedUrl(key, name, 'application/octet-stream', false, false, false, destinationBucket);
    return url.url;
  }
};

export const deleteDocs = async (files: Document[], destinationBucket?: string): Promise<DocumentDeleteResponse> => {
  const metadata = mapFilesToDocumentRequest(files, undefined, destinationBucket);
  return await deleteMongoDocuments(metadata);
};

export const downloadDocument = async (key: string, name: string, isStaged: boolean, bucket?: string): Promise<undefined> => {
  const url = await getPresignedUrl(key, name, 'application/octet-stream', false, isStaged, true, bucket);
  window.open(url.url, '_blank');
  return;
};
