import React, { ReactPropTypes, useEffect } from 'react';
import css from './styles/IdentificationModule.module.scss';
import { Truck } from 'types/Truck';
import { Trailer } from 'types/Trailer';
import FuelTypeSelect from 'components/common/FuelTypeSelect';
import StateAutoComplete from 'components/common/StateAutocomplete';
import TextField from '@mui/material/TextField';
import bindProperty from '../../utils/bindProperty';
import Avatar from '@mui/material/Avatar';
import FormControlLabel from '@mui/material/FormControlLabel';
import truckIcon from '../../assets/default-truck-edit.svg';
import CPSwitch from 'components/common/CPSwitch';
import classNames from 'classnames';
import Document from 'types/Document';
import * as documentService from 'services/documentService';
import ImageUploadDialog from 'components/common/ImageUploadDialog';
import { validImage } from 'utils/imageUtils';
import { useTranslation } from 'react-i18next';
import { FuelType } from 'types/FuelType';
import infoIcon from '../../assets/orange-info-icon.svg';

interface Props extends Partial<ReactPropTypes> {
  equipment: Truck | Trailer;
  handleChange: any;
  currentValidation: any;
  handleFuelTypeChange: Function;
  handleActiveChange: any;
  handleSave: Function;
  showSnackbar: Function;
  setValidation: Function;
  getEquipmentNumber: Function;
  setBlockOutsideClose: Function;
  isTruck: boolean;
  isVinDecodeEdit: boolean;
}

const IdentificationModule: React.FC<Props> = ({
  equipment,
  handleChange,
  currentValidation,
  handleFuelTypeChange,
  handleActiveChange,
  handleSave,
  showSnackbar,
  setValidation,
  getEquipmentNumber,
  setBlockOutsideClose,
  isTruck,
  isVinDecodeEdit,
}: Props) => {
  const [statesFocused, setStatesFocused] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState<string | undefined>(equipment.cachedImageUrl);
  const [document, setDocument] = React.useState<Document | undefined>(undefined);
  const [imageDialogOpen, setImageDialogOpen] = React.useState(false);

  const getDocument = async (): Promise<any> => {
    try {
      const documents: Document[] = (await documentService.getDocuments(equipment?.profileImageKey as string)).data as any;
      if (documents.length > 0) {
        const document = documents[0];
        setDocument(document);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getProfileImage = async (): Promise<any> => {
    try {
      if (document) {
        const image = await documentService.getPresignedUrl(
          document.key,
          document.name,
          'application/octet-stream',
          false,
          !!document.isNew,
          true,
        );
        setImageUrl(image.url);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (equipment?.profileImageKey && !equipment.cachedImageUrl) {
      if (validImage(equipment?.profileImageKey)) {
        setDocument({ key: equipment?.profileImageKey, name: equipment?.profileImageKey, isNew: false } as any);
      } else {
        getDocument();
      }
    } else if (!equipment.cachedImageUrl) {
      setDocument(undefined);
      setImageUrl(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipment?.profileImageKey]);

  useEffect(() => {
    if (document && !equipment.cachedImageUrl) {
      getProfileImage();
    } else if (!equipment.cachedImageUrl) {
      setImageUrl(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  const avatar = () => {
    const unitNumber = getEquipmentNumber(equipment);
    if (imageUrl) {
      return {
        src: imageUrl,
        children: unitNumber,
        imgProps: { onError: () => setImageUrl(undefined) },
      };
    }
    if (unitNumber) {
      return {
        sx: {
          bgcolor: '#C4C4C4',
        },
        children: unitNumber,
        variant: 'rounded' as any,
      };
    }
    return {
      src: truckIcon,
    };
  };

  const deleteImage = async () => {
    await documentService.deleteDocs(
      [(document as any) || { key: equipment?.profileImageKey, name: equipment?.profileImageKey }],
      equipment?.profileImageKey as string,
    );
    showSnackbar(t('Image deleted successfully'), 'success');
    setDocument(undefined);
    setImageUrl(undefined);
    handleSave(null);
  };

  const saveImage = async () => {
    await documentService.save([document as any], document?.key as string);
    await handleSave(document?.key);
    showSnackbar(t('Image saved successfully'), 'success');
  };

  const handleAvatarClick = () => {
    let validId = equipment?.id;
    if (validId) {
      setImageDialogOpen(true);
      setBlockOutsideClose(true);
    } else if (!validId) {
      showSnackbar(t("Please save first before uploading equipment's image"), 'info');
    }
  };

  const { t } = useTranslation();

  return (
    <div className={css.container}>
      <div className={css.image} onClick={handleAvatarClick}>
        <Avatar {...avatar()} />
        {imageUrl === undefined && (
          <div className={css.addPhotoLabel}>
            <label>{t('Add Photo')}</label>
          </div>
        )}
      </div>
      {isVinDecodeEdit && (
        <div className={css.vinEditMessage}>
          <img src={infoIcon} width={'14px'} height={'14px'} alt="infoIcon" />
          {` ${t('You are about to edit data')}`}
        </div>
      )}
      <div className={classNames(css.row, css.selectRow)}>
        <TextField
          name="unitNumber"
          margin="dense"
          label={`${t('Unit Number (required)')} `}
          variant={'filled'}
          value={bindProperty(getEquipmentNumber(equipment))}
          onChange={handleChange}
          error={currentValidation.unitNumber.hasError}
          helperText={currentValidation.unitNumber.message}
          onInput={(e: any) => {
            e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
          }}
        />
        <TextField
          name="vehicleIdentificationNumber"
          margin="dense"
          label="VIN"
          variant={'filled'}
          value={bindProperty(equipment?.vehicleIdentificationNumber)}
          onChange={handleChange}
          inputProps={{ maxLength: 17 }}
          error={currentValidation.vehicleIdentificationNumber.hasError}
          helperText={currentValidation.vehicleIdentificationNumber.message}
          onInput={(e: any) => {
            e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
          }}
        />
      </div>
      <div className={css.row}>
        <TextField
          name="licensePlateNumber"
          margin="dense"
          label={t('Plate Number')}
          variant={'filled'}
          value={bindProperty(equipment?.licensePlateNumber)}
          onChange={handleChange}
          inputProps={{ maxLength: 8 }}
          error={currentValidation.licensePlateNumber.hasError}
          helperText={currentValidation.licensePlateNumber.message}
          onInput={(e: any) => {
            e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
          }}
        />
        <StateAutoComplete
          maxLength={2}
          InputLabelProps={{ shrink: false }}
          className={classNames(css.states, {
            [css.statesFocused]: statesFocused || !!equipment?.licensePlateState,
          })}
          variant={'standard'}
          label={'Plate State'}
          stateShort={bindProperty(equipment?.licensePlateState)}
          onChange={handleChange}
          onBlur={() => setStatesFocused(false)}
          onFocus={() => setStatesFocused(true)}
          onInput={(e: any) => {
            e.target.value = e.target.value.replace(/[^a-zA-Z]/g, '');
          }}
          name="licensePlateState"
          helperText={currentValidation.licensePlateState.message}
          error={currentValidation.licensePlateState.hasError}
          fnSetError={(hasError: boolean) => {
            setValidation({
              ...currentValidation,
              licensePlateState: {
                hasError: hasError,
                message: !hasError ? '' : 'No data found',
              },
            });
          }}
        />
      </div>
      <div className={css.row}>
        <FormControlLabel
          classes={{ label: css.controlLabel, root: css.controlRoot }}
          control={
            <CPSwitch
              classes={{ root: equipment?.isActive ? css.cpSwitchActive : css.cpSwitch } as any}
              name="isActive"
              checked={equipment?.isActive ?? false}
              onChange={handleActiveChange}
              value={!equipment?.isActive}
            />
          }
          label={t('Active')}
        />
        {isTruck && <FuelTypeSelect type={equipment?.fuelType as FuelType} onChange={handleFuelTypeChange.bind(this)} />}
      </div>
      <ImageUploadDialog
        isOpen={imageDialogOpen}
        handleClose={() => {
          setImageDialogOpen(false);
          setBlockOutsideClose(false);
        }}
        imageUrl={imageUrl}
        deleteImage={deleteImage}
        saveImage={saveImage}
        setDocument={setDocument}
        showSnackBar={showSnackbar}
      />
    </div>
  );
};

export default IdentificationModule;
