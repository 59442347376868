import moment from 'moment';

export function isNullOrWhitespace(input: string): boolean {
  return !input || !input.trim();
}

export function formatPhoneNumber(phoneNumberString: string) {
  if (phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
  }
  return null;
}

export function formatCurrency(value: number) {
  let prefix = '';
  let amount = value && !isNaN(value) ? value : 0;
  if (value < 0) {
    prefix = '-';
    amount *= -1;
  }
  return `${prefix}$${amount.toFixed(2)}`;
}

export function verifyField(field: any): any {
  return field ? field : '--';
}

export function getDate(date: string, format = null): string {
  if (date) {
    let newDate = new Date(date.replace('Z', ''));
    return moment(newDate).format(format ? format : 'MM/DD/YYYY');
  } else {
    return date;
  }
}

export function epochToDate(epochDate: number | undefined, format: string = 'MM/DD/YYYY') {
  if (epochDate) {
    const formattedDate = moment(epochDate).format(format);
    return formattedDate;
  }
}
