/* eslint-disable jsx-a11y/alt-text */
import css from './styles/Document.module.scss';
import React, { MouseEvent, ReactElement, ReactPropTypes, Fragment } from 'react';
import Document from 'types/Document';
import { Document as PDF, Page } from 'react-pdf';
import { Close } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import * as documentService from 'services/documentService';
import placeholder from '../../assets/img-placeholder.svg';

export interface Props extends Partial<ReactPropTypes> {
  file: Document | any;
  isNew?: boolean;
  removeDocument?: Function;
  viewOnly?: boolean;
  saveDocument?: Function;
  showExtensionIcon?: boolean;
}

function DocumentComponent({ file, isNew, removeDocument, viewOnly, saveDocument, showExtensionIcon }: Props) {
  const [percentUploaded, setPercentUploaded] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    const uploadToStage = async () => {
      if (isNew) {
        const callback = (progress: ProgressEvent): void => {
          if (file.zeroBytes) {
            setPercentUploaded(100);
          } else {
            setPercentUploaded(Math.round((progress.loaded / progress.total) * 100));
          }
          if (progress.loaded === progress.total) {
            setIsLoading(false);
          }
        };
        if (file.zeroBytes) {
          setIsLoading(false);
        } else {
          setIsLoading(true);
          const options = { name: file['name'], getUrl: true };
          await documentService.uploadToStage(file['key'], file['type'] as any, file, callback, options);
          if (saveDocument) {
            saveDocument(file);
          }
        }
      }
    };
    if (!file.missingExtension) {
      uploadToStage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validImage = (): boolean => {
    switch (file['type']) {
      case 'image/jpeg':
      case 'image/svg+xml':
      case 'image/png':
        return true;
      default:
        return false;
    }
  };

  /* eslint-disable react/prop-types */
  const nameWithIcon = () => {
    const extension = file?.name?.split('.')?.pop()?.toLocaleUpperCase();
    return (
      <Fragment key={'nameWithIcon'}>
        <span className={css.extensionIcon} key={'extension'}>
          {extension}
        </span>
        <span key={'internalFilename'}>{file.name}</span>
      </Fragment>
    );
  };

  const renderThumbnail = (): ReactElement => {
    let view = <img key="placeholder" className={css.thumb} src={placeholder} />;
    if (file['type'] === 'application/pdf') {
      view = (
        <PDF className={css.thumb} loading="" renderMode="canvas" file={(file['thumbnail'] as any) || file} key={file.key}>
          <Page height={106} width={106} loading="" pageNumber={1} />
        </PDF>
      );
    } else if (validImage() && file['preview']) {
      view = <img key={file.key} className={css.thumb} src={file['preview']} />;
    } else if (file['thumbnail']) {
      view = <img key={file.key} className={css.thumb} src={`data:image/jpeg;base64,${file['thumbnail']}`} />;
    }
    return view;
  };

  const handleDeleteDocument = (event: MouseEvent, file: Document): void => {
    event.stopPropagation();
    removeDocument && removeDocument(file, isNew);
  };

  const handleDownloadDocument = async (event: MouseEvent, file: Document): Promise<any> => {
    event.stopPropagation();
    await documentService.downloadDocument(file['key'], file.name, !!isNew);
  };

  return (
    <div className={`${css.tile}  ${viewOnly ? css.viewOnly : ''}`}>
      {isLoading && (
        <div className={css.uploader}>
          <CircularProgress
            className={css.loadingSpinner}
            variant={percentUploaded === 0 ? 'indeterminate' : 'determinate'}
            value={percentUploaded}
            size={40}
          />
          <p className={css.loadingText}>Loading {percentUploaded}%</p>
        </div>
      )}
      {!isLoading && [
        <div key={file.name} className={css.thumbnailContainer}>
          {renderThumbnail()}
        </div>,
        <Fragment key={'filenameWrapper'}>
          {!file.missingExtension && !file.zeroBytes && (
            <p key="fileName" className={css.fileName}>
              {showExtensionIcon ? nameWithIcon() : file.name}
            </p>
          )}
        </Fragment>,
        <Fragment key={'noExtensionWrapper'}>
          {file.missingExtension && (
            <p key="no_extension" className={css.noExtension}>
              No Extension Unable to Save
            </p>
          )}
        </Fragment>,
        <Fragment key={'zeroBytesWrapper'}>
          {file.zeroBytes && (
            <p key="zero_bytes" className={css.noExtension}>
              Empty File Unable to Save
            </p>
          )}
        </Fragment>,
        <div
          key="overlay"
          className={`${css.overlay} ${viewOnly ? css.viewOnly : ''}`}
          onClick={file.missingExtension ? () => {} : (event): Promise<any> => handleDownloadDocument(event, file)}
        >
          {!viewOnly && (
            <Fragment key={'closeWrapper'}>
              <Close key="close" className={css.deleteIcon} aria-label="Delete" />
              <button key="closeButton" onClick={(event): void => handleDeleteDocument(event, file)} />
            </Fragment>
          )}
        </div>,
      ]}
    </div>
  );
}

export default DocumentComponent;
