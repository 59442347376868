import { ReactPropTypes } from 'react';
import AutoCompleteTextField from './AutoCompleteTextField';
import bindProperty from '../../utils/bindProperty';
import { match } from 'utils/match';
import { MenuItem } from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import classNames from 'classnames';
import { useAppSelector } from 'store';
import { getStateList } from 'store/reducers/appSlice';

interface Props extends Partial<ReactPropTypes> {
  stateShort: string;
  onChange: Function;
  className?: string;
  inputClass?: string;
  helperText?: string;
  condensedState?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
  InputLabelProps?: any;
  label?: string;
  placeholder?: string;
  error?: boolean;
  maxLength?: number;
  onFocus?: Function;
  onBlur?: Function;
  disabled?: boolean;
  name?: string;
  onInput?: Function;
  fnSetError?: Function;
}

function StateAutoComplete({
  stateShort,
  className,
  onChange,
  inputClass,
  helperText,
  condensedState,
  variant,
  maxLength,
  onFocus,
  onBlur,
  disabled,
  name,
  onInput,
  fnSetError,
  ...rest
}: Props) {
  const stateList = useAppSelector(getStateList);

  const getSuggestionValue = (suggestion: any): string => {
    return suggestion['code'];
  };

  const getSuggestions = (value: string) => {
    let count = 0;
    const max = 10;
    return stateList?.filter((suggestion: any) => {
      const keepName = count < max && suggestion['name'].toLowerCase().includes(value.toLowerCase());
      const keepCode = count < max && suggestion['code'].toLowerCase().includes(value.toLowerCase());
      if (keepName || keepCode) {
        count += 1;
      }
      return keepName || keepCode;
    });
  };

  const renderSuggestion = (suggestion: any, { query, isHighlighted }: any) => {
    const stateMatches = match(suggestion.name, query);
    const stateParts = parse(suggestion.name, stateMatches as any);
    const codeMatches = match(suggestion.code, query);
    const codeParts = parse(suggestion.code, codeMatches as any);
    return (
      <MenuItem selected={isHighlighted} component="div">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            flexDirection: 'row',
          }}
        >
          {!condensedState && (
            <div>
              {stateParts.map((part: any, index: any) =>
                part.highlight ? (
                  <span key={String(index)} style={{ fontWeight: 600, fontSize: '16px' }}>
                    {part.text}
                  </span>
                ) : (
                  <strong key={String(index)} style={{ fontWeight: 400, fontSize: '16px' }}>
                    {part.text}
                  </strong>
                ),
              )}
            </div>
          )}
          {!condensedState && (
            <div style={{ fontWeight: 300, fontSize: '16px', paddingLeft: '4px', paddingRight: '4px' }} className={'stateShort'}>
              {'-'}
            </div>
          )}
          {codeParts.map((part: any, index: any) =>
            part.highlight ? (
              <span key={String(index)} style={{ fontWeight: 600, fontSize: '16px' }}>
                {part.text}
              </span>
            ) : (
              <strong key={String(index)} style={{ fontWeight: 400, fontSize: '16px' }}>
                {part.text}
              </strong>
            ),
          )}
        </div>
      </MenuItem>
    );
  };

  const handleStateSelected = (suggestion: any) => {
    onChange({
      target: {
        name: name ? name : 'state',
        value: suggestion['code'],
      },
    });
  };

  const validateState = (value: string | null = null) => {
    let toValidate = value ? value : stateShort;
    if (stateList?.find((x) => x.code === toValidate) === undefined) {
      if (fnSetError) fnSetError(true);
      return false;
    } else {
      if (fnSetError) fnSetError(false);
      return true;
    }
  };

  return (
    <AutoCompleteTextField
      maxLength={maxLength}
      shouldRenderSuggestions={() => stateList && stateList?.length > 0}
      className={className}
      name={name ? name : 'state'}
      label={rest.label}
      placeholder={rest.placeholder}
      searchList={stateList}
      maxResultsDisplayed={10}
      value={bindProperty(stateShort)}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      onBlur={(e: any) => {
        if (!validateState()) onChange({ target: { name: 'state', value: '' } } as any);
        onBlur && onBlur(e);
      }}
      variant={variant}
      // @ts-ignore
      helperText={rest.helperText}
      error={rest.error}
      selectSuggestion={(_event: any, { suggestion }: any) => handleStateSelected(suggestion)}
      onChange={onChange as any}
      getSuggestions={getSuggestions as any}
      inputClass={classNames(inputClass)}
      InputLabelProps={rest.InputLabelProps}
      onFocus={onFocus}
      disabled={disabled}
      onInput={(e: any) => {
        let value: string = e.target.value.replace(/[^a-zA-Z]/g, '').toLocaleUpperCase();
        validateState(value);
        if (onInput) onInput(e);
      }}
    />
  );
}

export default StateAutoComplete;
