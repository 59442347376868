/**
 * It compress the size of an image based on an URL
 *
 * @param imgUrl - Image's URL.
 * @param maxWidth - Max width for the compressed image.
 * @param maxHeight - Max height for the compressed image.
 * @return Promise<string> New URL with the compressed image.
 **/
export async function compressUrlImage(imgUrl: string, maxWidth: number, maxHeight: number): Promise<string> {
  let localUrl: string = imgUrl;
  try {
    let blob = await fetch(imgUrl).then((image) => image.blob());
    let blobURL = window.URL.createObjectURL(blob);
    let img = new Image();

    img.src = blobURL;

    img.onerror = () => {
      localUrl = imgUrl;
    };

    img.onload = (ev) => {
      window.URL.revokeObjectURL(blobURL); // release memory
      let canvas = document.createElement('canvas');
      let newSize = calculateAspectRatioFit(img, maxWidth, maxHeight);
      canvas.width = newSize.width;
      canvas.height = newSize.height;

      let ctx = canvas.getContext('2d');
      ctx?.drawImage(img, 0, 0, newSize.width, newSize.height);

      canvas.toBlob(
        (compressedBlob) => {
          localUrl = window.URL.createObjectURL(compressedBlob as any);
        },
        blob.type,
        0.9, // 90% of quality
      );
    };
    await awaitValue(localUrl);
  } catch (error) {
    localUrl = imgUrl;
  }

  return localUrl;
}

/**
 * It resize an image's file
 *
 * @param imgFile - Image's file.
 * @param maxWidth - Max width for the compressed image.
 * @param maxHeight - Max height for the compressed image.
 * @return Promise<File> New resized file.
 **/
export async function resizeImageFile(imgFile: File, maxWidth: number | null = null, maxHeight: number | null = null): Promise<File> {
  let resizedFile: File = imgFile;
  try {
    const blob = imgFile as Blob;
    let blobURL = window.URL.createObjectURL(blob);
    let img = new Image();

    img.src = blobURL;
    img.onerror = () => {
      resizedFile = imgFile;
    };

    img.onload = (ev) => {
      window.URL.revokeObjectURL(blobURL); // release memory
      let canvas = document.createElement('canvas');
      let newSize = calculateAspectRatioFit(img, maxWidth as number, maxHeight as number);
      canvas.width = newSize.width;
      canvas.height = newSize.height;

      let ctx = canvas.getContext('2d');
      ctx?.drawImage(img, 0, 0, newSize.width, newSize.height);

      canvas.toBlob(
        (compressedBlob) => {
          let opts: FilePropertyBag = {
            type: blob.type,
          };
          resizedFile = new File([compressedBlob as any], imgFile.name, opts);
        },
        blob.type,
        0.9, // 90% of quality
      );
    };

    await awaitValue(resizedFile);
  } catch (error) {
    resizedFile = imgFile;
  }

  return resizedFile;
}

export const validImage = (file: any): boolean => {
  switch (file['type']) {
    case 'image/jpeg':
    case 'image/svg+xml':
    case 'image/png':
      return true;
    default:
      return false;
  }
};

function calculateAspectRatioFit(image: HTMLImageElement, maxWidth: number, maxHeight: number) {
  let ratio: number = Math.min(maxWidth / image.width, maxHeight / image.height);
  return { width: image.width * ratio, height: image.height * ratio };
}

async function awaitValue(value: any, loopValidation: number = 0) {
  if (!value && loopValidation <= 3) {
    loopValidation++;
    await timeout();
    awaitValue(value, loopValidation);
  }
}

function timeout() {
  return new Promise((resolve) => setTimeout(resolve, 500));
}
