import { ReactPropTypes, useState } from 'react';
import css from './styles/FilterChipBar.module.scss';
import { useTranslation } from 'react-i18next';
import CloverThemeProvider from 'shamrock-clover-ui/dist/clover/Theme/ThemeProvider';
import { Chip } from 'shamrock-clover-ui/dist/clover/components/Chip/Chip';

interface Props extends Partial<ReactPropTypes> {
  type: 'truck' | 'trailer';
  allItemCount?: number;
  activeItemCount?: number;
  inactiveItemCount?: number;
  filter: Function;
}

const FilterChipBar: React.FC<Props> = ({ type, allItemCount, activeItemCount, inactiveItemCount, filter }: Props) => {
  const { t } = useTranslation();
  const [active, setActive] = useState('all');

  const getLabel = (label: string) => {
    return ` ${t(label)} ${label === 'Active' ? activeItemCount : inactiveItemCount}`;
  };

  const getChipState = (count?: number, active?: boolean) => {
    if (active) {
      return 'selected';
    }
    if (count && count > 0) {
      return 'enabled';
    }
    return 'disabled';
  };

  return (
    <CloverThemeProvider>
      <div className={css.chips}>
        <div className={css.chipWrapper}>
          <Chip
            variant="small"
            key="all"
            label={`All ${allItemCount}`}
            leftIcon={<span className={css.dotAll}></span>}
            state={getChipState(allItemCount, active === 'all')}
            onClick={() => {
              if (allItemCount && allItemCount > 0 && active !== 'all') {
                setActive('all');
                filter('all');
              }
            }}
          />
        </div>
        <div className={css.chipWrapper}>
          <Chip
            variant="small"
            key="active"
            label={getLabel('Active')}
            leftIcon={<span className={css.dotActive}></span>}
            state={getChipState(activeItemCount, active === 'active')}
            onClick={() => {
              if (activeItemCount && activeItemCount > 0 && active !== 'active') {
                setActive('active');
                filter('active');
              } else {
                setActive('all');
                filter('all');
              }
            }}
          />
        </div>
        <div className={css.chipWrapper}>
          <Chip
            variant="small"
            key="inactive"
            label={getLabel('Inactive')}
            leftIcon={<span className={css.dotInactive}></span>}
            state={getChipState(inactiveItemCount, active === 'inactive')}
            onClick={() => {
              if (inactiveItemCount && inactiveItemCount > 0 && active !== 'inactive') {
                setActive('inactive');
                filter('inactive');
              } else {
                setActive('all');
                filter('all');
              }
            }}
          />
        </div>
      </div>
    </CloverThemeProvider>
  );
};

export default FilterChipBar;
