import React, { ReactPropTypes } from 'react';
import css from './styles/TruckBrandModule.module.scss';
import { Equipment } from 'types/Equipment';
import TextField from '@mui/material/TextField';
import bindProperty from 'utils/bindProperty';
import { useTranslation } from 'react-i18next';
import infoIcon from '../../assets/orange-info-icon.svg';

interface Props extends Partial<ReactPropTypes> {
  equipment: Equipment;
  handleChange: any;
  currentValidation: any;
  isVinDecodeEdit: boolean;
}

const TruckBrandModule: React.FC<Props> = ({ equipment, handleChange, currentValidation, isVinDecodeEdit }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={css.container}>
      {isVinDecodeEdit && (
        <div className={css.vinEditMessage}>
          <img src={infoIcon} width={'14px'} height={'14px'} alt="infoIcon" />
          {` ${t('You are about to edit data')}`}
        </div>
      )}
      <div className={css.row}>
        <TextField
          name="make"
          margin="dense"
          label={t('Make')}
          variant={'filled'}
          value={bindProperty(equipment?.make)}
          onChange={handleChange}
        />
        <TextField
          name="model"
          margin="dense"
          label={t('Model')}
          variant={'filled'}
          value={bindProperty(equipment?.model)}
          onChange={handleChange}
        />
      </div>
      <div className={css.row}>
        <TextField
          name="year"
          margin="dense"
          label={t('Year')}
          variant={'filled'}
          type="number"
          inputProps={{ min: '1900', max: '2050' }}
          error={currentValidation.year.hasError}
          helperText={currentValidation.year.message}
          value={bindProperty(equipment?.year)}
          onChange={handleChange}
          onInput={(e: any) => {
            e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 4);
          }}
        />
      </div>
    </div>
  );
};

export default TruckBrandModule;
