/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment } from 'react';
import * as equipmentService from 'services/equipmentService';
import { MultilineTextInput } from 'shamrock-clover-ui';
import { Comment } from './Comment';
import { Note } from 'types/Note';
import css from './styles/CommentModule.module.scss';
import scss from 'components/container/styles/Equipment.module.scss';
import noteSendIcon from '../../assets/note-send.svg';
import FirebaseAnalytics from 'utils/firebaseAnalytics';
import { Truck } from 'types/Truck';
import { removeFromArray, replaceInArray } from '../../utils/arrayUtils';
import LoadingSpinner from './LoadingSpinner';
import { Trailer } from 'types/Trailer';
import { useTranslation } from 'react-i18next';

interface Props {
  equipment: Truck | Trailer;
  isTruck: boolean;
  showSnackbar: Function;
  customContainerStyles?: any;
}

function CommentModule({ equipment, isTruck, showSnackbar, customContainerStyles }: Props) {
  const { t } = useTranslation();
  const [newMessage, setNewMessage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [notes, setNotes] = React.useState([] as Note[]);

  const loadData = async () => {
    if (equipment.id) {
      setIsLoading(true);
      let notes: Note[] = isTruck
        ? (await equipmentService.getTruckNotes(equipment.id as any)).data
        : (await equipmentService.getTrailerNotes(equipment.id as any)).data;

      if (notes) {
        setNotes(notes);
      }
      setIsLoading(false);
    } else {
      setNotes([]);
    }
  };

  React.useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipment.id]);

  const handleChange = (event: any) => {
    setNewMessage(event?.target?.value);
  };

  const handleSubmitMessage = async () => {
    if (!isLoading && newMessage !== '') {
      const newNote: any = {
        note: newMessage,
      };
      setNewMessage('');
      setIsLoading(true);
      try {
        if (isTruck) await equipmentService.addTruckNote(newNote, equipment.id as any);
        else await equipmentService.addTrailerNote(newNote, equipment.id as any);

        await loadData();
        FirebaseAnalytics.sendEvent(
          FirebaseAnalytics.EVENTS.CREATE,
          FirebaseAnalytics.MODULES.ENTERPRISE,
          FirebaseAnalytics.PAGES.EQUIPMENT,
          {
            label: isTruck ? FirebaseAnalytics.LABELS.ADD_NOTES_TRUCK : FirebaseAnalytics.LABELS.ADD_NOTES_TRAILER,
          },
        );
      } catch (e: any) {
        showSnackbar(e?.response?.data?.toString() || t('Error Saving Note.'), 'error');
      }
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSubmitMessage();
    }
  };

  const handleOnDelete = async (note: Note) => {
    const index = notes.indexOf(note);
    if (index !== -1) {
      const newNotes = removeFromArray(notes, index);
      setNotes(newNotes);
    }
  };

  const handleOnSave = async (oldNote: Note, newNote: Note) => {
    const index = notes.indexOf(oldNote);
    if (index !== -1) {
      const newNotes = replaceInArray(notes, { ...newNote, isEdited: true }, index);
      setNotes(newNotes);
    }
  };

  const handleEditNote = async (note: Note, message: string) => {
    if (isTruck) equipmentService.editTruckNote(note.noteId, equipment.id as any, message);
    else equipmentService.editTrailerNote(note.noteId, equipment.id as any, message);
  };

  const handleDeleteNote = async (note: Note) => {
    if (isTruck) equipmentService.deleteTruckNote(note.noteId, equipment.id as any);
    else equipmentService.deleteTrailerNote(note.noteId, equipment.id as any);
  };

  return (
    <Fragment>
      {equipment.id ? (
        <>
          <div className={css.field} style={isTruck ? { paddingTop: '20' } : { paddingTop: '40' }} onKeyPress={handleKeyPress}>
            <MultilineTextInput
              label={newMessage.length ? undefined : t('Add Note')}
              variant="filled"
              rows={1}
              characterLimit={1000}
              value={newMessage}
              onChange={handleChange}
            />
            <img className={css.noteSendIcon} onClick={handleSubmitMessage} src={noteSendIcon} />
          </div>
          <div className={css.container} style={customContainerStyles}>
            <div className={css.messagesContainer}>
              {isLoading && <LoadingSpinner customStyles={{ backgroundColor: '#cccccc', inset: 0 }} />}
              {!isLoading &&
                notes &&
                notes.length > 0 &&
                notes.map((note, index) => (
                  <Comment
                    key={index}
                    note={note}
                    onDelete={handleOnDelete}
                    onSave={handleOnSave}
                    showSnackbar={showSnackbar}
                    editNote={(message: any) => handleEditNote(note, message)}
                    deleteNote={() => handleDeleteNote(note)}
                  />
                ))}
            </div>
          </div>
        </>
      ) : (
        <div className={scss.noNotes}>Please save before using comments.</div>
      )}
    </Fragment>
  );
}

export default CommentModule;
