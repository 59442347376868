import { Card, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { Fragment } from 'react';
import css from './styles/DocumentCardList.module.scss';
import Document from 'types/Document';
import { useTranslation } from 'react-i18next';
import * as documentService from 'services/documentService';

interface Props {
  documents: Document[];
  handleDetailClick: Function;
}

function DocumentCardList({ documents, handleDetailClick }: Props) {
  const extractFileExtension = (fileName: string) => {
    return fileName?.split('.')?.pop()?.toLocaleUpperCase();
  };

  const downloadDocument = (doc: Document) => {
    documentService.downloadDocument(doc.key, doc.name, !!doc.isNew);
  };
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className={css.cardTitle}>
        <label>{t('Files')}</label>
        <ArrowForward className={css.actionIcon} onClick={() => handleDetailClick()} />
      </div>
      <Card className={css.cardFiles}>
        {documents && documents.length > 0 ? (
          <List component="nav" className={css.filesList}>
            {documents.map((doc) => (
              <ListItem key={doc._id}>
                <ListItemIcon>
                  <div className={css.fileExtension}>{extractFileExtension(doc.name)}</div>
                </ListItemIcon>
                <ListItemText
                  onClick={() => downloadDocument(doc)}
                  className={css.fileText}
                  primaryTypographyProps={{ fontSize: '16px', fontWeight: 'bold' }}
                >
                  {doc.name}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        ) : (
          <div className={css.noFiles}>There are not any files in this profile.</div>
        )}
      </Card>
    </Fragment>
  );
}

export default DocumentCardList;
