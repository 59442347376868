import LocalAuth from '@shamrock-core/common/authentication/local-authentication';

export const getCompanyId: any = async () => {
  const company = window.localStorage.getItem(LocalAuth.COMPANY_KEY);
  if (company) {
    const json = JSON.parse(company);
    return json.company_id;
  }
  return null;
};

export const getCompanyMongoId: any = async () => {
  const company = window.localStorage.getItem(LocalAuth.COMPANY_KEY);
  if (company) {
    const json = JSON.parse(company);
    return json._id;
  }
  return null;
};
