import { PaletteThemeProps } from '../../types/theme';
import { PalettesProps } from '@ant-design/colors';
import { PaletteColorOptions } from '@mui/material/styles';
import { ThemeMode } from '../../types/config';

const PtTheme = (colors: PalettesProps, mode: ThemeMode): PaletteThemeProps => {
  // NOTE: Taking all colors schemes from default except primary
  // To modify one from default, remove it from this spread operator and add a custom array like primaryColors below.
  // primaryColors and secondary has ten colors in the array with index 5 being the main color.
  // errorColors, warningColors, infoColors, successColors have 5 colors in the array
  // See other theme files to see how to override.
  // SEE: https://codedthemes.gitbook.io/mantis/theme-config
  // SEE: https://mui.com/material-ui/customization/theming/
  // Tools to help
  // https://m2.material.io/inline-tools/color/
  // https://zenoo.github.io/mui-theme-creator
  const { grey, red, gold, cyan, green } = colors;
  const greyColors: PaletteColorOptions = {
    0: grey[0],
    50: grey[1],
    100: grey[2],
    200: grey[3],
    300: grey[4],
    400: grey[5],
    500: grey[6],
    600: grey[7],
    700: grey[8],
    800: grey[9],
    900: grey[10],
    A50: grey[15],
    A100: grey[11],
    A200: grey[12],
    A400: grey[13],
    A700: grey[14],
    A800: grey[16],
  };
  const contrastText = '#fff';

  let primaryColors = ['#e2f1fc', '#b9dcf8', '#69C0FF', '#5eb0ef', '#38a0ed', '#0091EA', '#0083dc', '#0071ca', '#0060b8', '#004399'];

  return {
    primary: {
      lighter: primaryColors[0],
      100: primaryColors[1],
      200: primaryColors[2],
      light: primaryColors[3],
      400: primaryColors[4],
      main: primaryColors[5],
      dark: primaryColors[6],
      700: primaryColors[7],
      darker: primaryColors[8],
      900: primaryColors[9],
      contrastText,
    },
    secondary: {
      lighter: greyColors[100],
      100: greyColors[100],
      200: greyColors[200],
      light: greyColors[300],
      400: greyColors[400],
      main: greyColors[500]!,
      600: greyColors[600],
      dark: greyColors[700],
      800: greyColors[800],
      darker: greyColors[900],
      A100: greyColors[0],
      A200: greyColors.A400,
      A300: greyColors.A700,
      contrastText: greyColors[0],
    },
    error: {
      lighter: red[0],
      light: red[2],
      main: red[4],
      dark: red[7],
      darker: red[9],
      contrastText,
    },
    warning: {
      lighter: gold[0],
      light: gold[3],
      main: gold[5],
      dark: gold[7],
      darker: gold[9],
      contrastText: greyColors[100],
    },
    info: {
      lighter: cyan[0],
      light: cyan[3],
      main: cyan[5],
      dark: cyan[7],
      darker: cyan[9],
      contrastText,
    },
    success: {
      lighter: green[0],
      light: green[3],
      main: green[5],
      dark: green[7],
      darker: green[9],
      contrastText,
    },
    grey: greyColors,
  };
};

export default PtTheme;
