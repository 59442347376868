import React, { ReactPropTypes } from 'react';
import css from './styles/TruckDescriptionModule.module.scss';
import { Truck } from 'types/Truck';
import TextField from '@mui/material/TextField';
import bindProperty from 'utils/bindProperty';
import { useTranslation } from 'react-i18next';
import infoIcon from '../../assets/orange-info-icon.svg';

interface Props extends Partial<ReactPropTypes> {
  truck: Truck;
  handleTruckChange: any;
  isVinDecodeEdit: boolean;
}

const TruckDescriptionModule: React.FC<Props> = ({ truck, handleTruckChange, isVinDecodeEdit }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={css.container}>
      {isVinDecodeEdit && (
        <div className={css.vinEditMessage}>
          <img src={infoIcon} width={'14px'} height={'14px'} alt="infoIcon" />
          {` ${t('You are about to edit data')}`}
        </div>
      )}
      <div className={css.row}>
        <TextField
          name="color"
          margin="dense"
          label={t('Color')}
          variant={'filled'}
          value={bindProperty(truck?.color)}
          onChange={handleTruckChange}
        />
        <TextField
          name="truckType"
          margin="dense"
          label={t('Type')}
          variant={'filled'}
          value={bindProperty(truck?.truckType)}
          onChange={handleTruckChange}
        />
      </div>
    </div>
  );
};

export default TruckDescriptionModule;
