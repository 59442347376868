import React, { Fragment, ReactPropTypes } from 'react';
import css from './styles/FilesModule.module.scss';
import scss from 'components/container/styles/Equipment.module.scss';
import { Equipment } from 'types/Equipment';
import Document from 'types/Document';
import useDocumentUtils from 'hooks/useDocumentUtils';
import * as documentService from 'services/documentService';
import { Guid } from 'guid-typescript';
import DocumentUpload from './DocumentUpload';
import classNames from 'classnames';
import ConfirmationDialog from './ConfirmationDialog';
import FirebaseAnalytics from 'utils/firebaseAnalytics';
import { useTranslation } from 'react-i18next';

interface Props extends Partial<ReactPropTypes> {
  item: Equipment;
  setItem: Function;
  saveItem: Function;
  setIsLoading: Function;
  showSnackbar: Function;
}

const FilesModule: React.FC<Props> = ({ item, setItem, saveItem, setIsLoading, showSnackbar }: Props) => {
  const { t } = useTranslation();
  const [currentDocuments, setCurrentDocuments] = React.useState<Document[]>([]);
  const [newDocuments, setNewDocuments] = React.useState<Document[]>([]);
  const validId = item?.id;
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [fileToRemove, setFileToRemove] = React.useState<{ document: Document | null; isNew: boolean | null }>({
    document: null,
    isNew: null,
  });

  React.useEffect(() => {
    if (item?.transactionKey) {
      getDocuments(item?.transactionKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.transactionKey || validId]);

  const getDocuments = async (transactionKey: string): Promise<any> => {
    try {
      const documents: Document[] = (await documentService.getDocuments(item?.transactionKey as string)).data as any;
      setCurrentDocuments(documents);
    } catch (e) {
      console.error(e);
    }
  };

  const handleRemoveFile = async () => {
    setOpenDeleteDialog(false);
    setIsLoading(true);
    const docToDelete = fileToRemove.isNew ? newDocuments.find((doc) => doc.key === fileToRemove?.document?.key) : fileToRemove.document;
    await documentService.deleteDocs([docToDelete as Document], item?.transactionKey);
    setCurrentDocuments(currentDocuments.filter((doc) => doc.key !== fileToRemove?.document?.key));
    setNewDocuments(newDocuments.filter((doc) => doc.key !== fileToRemove?.document?.key));
    setIsLoading(false);
  };

  const onRemoveDocument = (document: Document, isNew: boolean) => {
    setFileToRemove({ document, isNew });
    setOpenDeleteDialog(true);
  };

  const onAddDocuments = (documents: Document[]) => {
    setCurrentDocuments([...currentDocuments, ...documents]);
  };

  const saveDocument = async (document: Document) => {
    if (!newDocuments.find((doc) => doc.key === document.key)) {
      let transactionKey = item?.transactionKey;
      if (!transactionKey) {
        transactionKey = Guid.create().toString();
        setItem({ ...item, transactionKey });
        saveItem();
      }
      const {
        completedFiles: [{ _id }],
      } = await documentService.save([document], transactionKey);
      setNewDocuments((currentState) => {
        return [...currentState, { ...document, _id }];
      });
      FirebaseAnalytics.sendEvent(
        FirebaseAnalytics.EVENTS.CREATE,
        FirebaseAnalytics.MODULES.ENTERPRISE,
        FirebaseAnalytics.PAGES.EQUIPMENT,
        {
          label: 'fuelType' in item ? FirebaseAnalytics.LABELS.DOCUMENT_TRUCK : FirebaseAnalytics.LABELS.DOCUMENT_DRIVER,
        },
      );
    }
  };

  const showWarningMsg = (message: string, type: string) => {
    showSnackbar(message, type);
  };

  const { getRootProps, getInputProps, currentFilesRender, open } = useDocumentUtils(
    onRemoveDocument,
    onAddDocuments,
    currentDocuments,
    newDocuments,
    saveDocument,
    true,
    false,
    () => showWarningMsg(t('File size is too large, please upload file smaller than 18MB'), 'error'),
  );

  return (
    <Fragment>
      <ConfirmationDialog
        open={openDeleteDialog || false}
        onDialogClose={() => setOpenDeleteDialog(false)}
        onPrimaryActionCompleted={handleRemoveFile}
        title={t('Remove file?')}
        message={t('Removing a file cannot be undone.')}
      />
      {validId ? (
        <div
          {...getRootProps({
            className: classNames(css.container, { [css.scrollY]: 'fuelType' in item && currentFilesRender.length > 3 }),
          } as any)}
        >
          <input {...getInputProps()} />
          <div className={css.docs}>{currentFilesRender}</div>
          <DocumentUpload open={open} />
        </div>
      ) : (
        <div className={scss.noNotes}>Please save before using files.</div>
      )}
    </Fragment>
  );
};

export default FilesModule;
