import React, { useEffect } from 'react';
import css from './styles/EquipmentCard.module.scss';
import Document from 'types/Document';
import { useDispatch } from 'react-redux';
import { compressUrlImage, validImage } from '../../utils/imageUtils';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { updateTruckInList, updateTrailerInList } from 'store/reducers/appSlice';
import { Truck } from 'types/Truck';
import { Trailer } from 'types/Trailer';
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
import Avatar from '@mui/material/Avatar';
import * as equipmentService from 'services/equipmentService';
import * as documentService from 'services/documentService';
import ImageUploadDialog from 'components/common/ImageUploadDialog';

export interface EquipmentCardProps {
  item: Truck | Trailer;
  index: number;
  onClick: (index: number) => void;
  showSnackbar?: any;
}

const EquipmentCard = ({ item, index, onClick, showSnackbar }: EquipmentCardProps) => {
  const [imageUrl, setImageUrl] = React.useState<string | undefined>(item.cachedImageUrl);
  const [imageDialogOpen, setImageDialogOpen] = React.useState(false);
  const [document, setDocument] = React.useState<Document | undefined>(undefined);
  const dispatch = useDispatch();
  const [allowEditing, setAllowEditing] = React.useState(true);
  const isTruck = item.equipmentType === 'truck';
  const isTrailer = item.equipmentType === 'trailer';

  const getTruckOrTrailerNumber = () => {
    if (isTruck) {
      return (item as Truck).truckNumber;
    }
    if (isTrailer) {
      return (item as Trailer).trailerNumber;
    }
  };

  const getFuelOrTrailerType = () => {
    if (isTruck) {
      return (item as Truck).fuelType?.name;
    }
    if (isTrailer) {
      return (item as Trailer).trailerTypeName;
    }
  };

  const findProfileImage = async (): Promise<any> => {
    try {
      const documents: Document[] = (await documentService.getDocuments(item?.profileImageKey as string)).data as any;
      if (documents.length > 0) {
        const document = documents[0];
        setDocument(document);
        const image = await documentService.getPresignedUrl(document.key, document.name, 'application/octet-stream', false, false, true);
        let compressedImageUrl = await compressUrlImage(image.url, 160, 110);
        setImageUrl(compressedImageUrl);
        if (isTruck) {
          dispatch(updateTruckInList({ ...item, cachedImageUrl: compressedImageUrl }));
        } else {
          dispatch(updateTrailerInList({ ...item, cachedImageUrl: compressedImageUrl } as Trailer));
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getProfileImage = async (profileImageKey?: string, isNew = false): Promise<any> => {
    if (profileImageKey) {
      const image = await documentService.getPresignedUrl(profileImageKey, profileImageKey, 'application/octet-stream', false, isNew, true);
      setImageUrl(image.url);
      if (isTruck) {
        dispatch(updateTruckInList({ ...item, cachedImageUrl: image.url }));
      } else {
        dispatch(updateTrailerInList({ ...item, cachedImageUrl: image.url } as Trailer));
      }
    }
  };

  useEffect(() => {
    const getImage = async () => await getProfileImage(item?.profileImageKey as string);
    if (item?.profileImageKey && !item.cachedImageUrl) {
      if (validImage(item?.profileImageKey)) {
        getImage().catch(console.error);
      } else {
        findProfileImage();
      }
    } else if (!item.cachedImageUrl) {
      setImageUrl(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.profileImageKey]);

  const click = () => {
    if (allowEditing) {
      onClick(index);
    }
  };

  useEffect(() => {
    const getImage = async () => await getProfileImage(document?.key, true);
    if (document && document.isNew) {
      getImage().catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  const stringAvatar = () => {
    if (imageUrl) {
      return {
        src: imageUrl,
        children: getTruckOrTrailerNumber(),
      };
    }
    return {
      sx: {
        bgcolor: '#C4C4C4',
      },
      children: getTruckOrTrailerNumber(),
      variant: 'rounded' as any,
    };
  };

  const { t } = useTranslation();

  const saveTruck = async (truck: Truck) => {
    try {
      truck.id ? await equipmentService.updateTruck(truck) : await equipmentService.createTruck(truck);
      showSnackbar(t('Truck saved successfully'), 'success');
    } catch (e) {
      console.error('Error:', e);
      showSnackbar(t('Unknown error saving truck'), 'error');
    }
  };

  const saveTrailer = async (trailer: Trailer) => {
    try {
      trailer.id ? await equipmentService.updateTrailer(trailer) : await equipmentService.createTrailer(trailer);
      showSnackbar(t('Trailer saved successfully'), 'success');
    } catch (e) {
      console.error('Error:', e);
      showSnackbar(t('Unknown error saving trailer'), 'error');
    }
  };

  const deleteImage = async () => {
    await documentService.deleteDocs(
      [(document as any) || { key: item?.profileImageKey, name: item?.profileImageKey }],
      item?.profileImageKey as any,
    );
    const itemToSave = { ...item, profileImageKey: null };
    if (isTruck) {
      dispatch(updateTruckInList({ ...itemToSave, cachedImageUrl: undefined } as Truck));
      await saveTruck(itemToSave as Truck);
    } else {
      dispatch(updateTrailerInList({ ...itemToSave, cachedImageUrl: undefined } as Trailer));
      await saveTrailer(itemToSave as Trailer);
    }
    setDocument(undefined);
    setImageUrl(undefined);
  };

  const saveImage = async () => {
    await documentService.save([document as Document], document?.key as string);
    const itemToSave = { ...item, profileImageKey: document?.key };
    if (isTruck) {
      dispatch(updateTruckInList(itemToSave as Truck));
      await saveTruck(itemToSave as Truck);
    } else {
      dispatch(updateTrailerInList(itemToSave as Trailer));
      await saveTrailer(itemToSave as Trailer);
    }
  };

  const onSwitchChanged = async () => {
    try {
      const editedItem = { ...item };
      editedItem.isActive = !editedItem.isActive;
      if (isTruck) {
        await equipmentService.updateTruckActive(editedItem.id as number, editedItem.isActive);
        dispatch(updateTruckInList(editedItem as Truck));
      } else {
        await equipmentService.updateTrailerActive(editedItem.id as number, editedItem.isActive);
        dispatch(updateTrailerInList(editedItem as Trailer));
      }
    } catch (exception) {
      showSnackbar(`An error occurred while switching ${isTruck ? 'truck' : 'trailer'} state`, 'error');
    }
  };

  return (
    <Card className={css.equipmentCard}>
      <div>
        <div className={css.header}>
          <span className={css.ownerType}>{isTruck ? t('Truck') : t('Trailer')}</span>
          <div className={css.active}>
            <label>{item.isActive ? t('Active') : t('Inactive')}</label>
            <Switch checked={item.isActive || false} onChange={onSwitchChanged} value={!item.isActive} name="isActive" color="primary" />
          </div>
        </div>
        <div id="clickable-container" onClick={click}>
          <div className={css.imageWrapper}>
            <div
              className={css.image}
              onClick={(e: any) => {
                setImageDialogOpen(true);
                e.stopPropagation();
                setAllowEditing(false);
              }}
            >
              <Avatar {...stringAvatar()} />
              {imageUrl === undefined && (
                <div className={css.addPhotoLabel}>
                  <label>{t('Add Photo')}</label>
                </div>
              )}
            </div>
          </div>
          <div className={css.textWrapper}>
            <div className={classNames(css.textContainer, css.textColumn)}>
              <div className={css.label}>{isTruck ? t('Truck Number') : t('Trailer Number')}</div>
              <div className={css.value}>{getTruckOrTrailerNumber()}</div>
              <div className={css.textContainer}>
                <div className={css.label}>{t('Type')}</div>
                <div className={css.value}>{getFuelOrTrailerType()}</div>
              </div>
            </div>
            <div className={classNames(css.textContainer, css.textColumn)}>
              <div className={css.textContainer}>
                <div className={css.label}>{t('Plate Number')}:</div>
                <div className={css.value}>{item?.licensePlateNumber ? item?.licensePlateNumber : '--'}</div>
              </div>
              <div className={css.textContainer}>
                <div className={css.label}>{t('Plate State')}:</div>
                <div className={css.value}>{item?.licensePlateState ? item?.licensePlateState : '--'}</div>
              </div>
            </div>
          </div>
          <div className={css.imageDialog}>
            <ImageUploadDialog
              isOpen={imageDialogOpen}
              handleClose={() => {
                setImageDialogOpen(false);
                setAllowEditing(true);
              }}
              imageUrl={imageUrl}
              deleteImage={deleteImage}
              saveImage={saveImage}
              setDocument={setDocument}
              showSnackBar={showSnackbar}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default EquipmentCard;
