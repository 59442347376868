import { ReactPropTypes } from 'react';
import css from './styles/CustomAutoComplete.module.scss';
import AutoCompleteTextField from './AutoCompleteTextField';
import { match } from 'utils/match';
import { MenuItem } from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import classNames from 'classnames';
import React from 'react';

interface Props extends Partial<ReactPropTypes> {
  value: string;
  onChange: Function;
  label: string;
  name: string;
  suggestionList: any;
  suggestionField: string;
  helperText?: string;
  variant?: 'standard' | 'outlined' | 'filled';
  error?: boolean;
  disabled?: boolean;
  fnSetError?: Function;
}

function MakeAutoComplete({ value, onChange, variant, suggestionList, label, name, suggestionField, disabled }: Props) {
  const [focused, setFocused] = React.useState(false);
  const haveValueOrFocused = focused || !!value;

  const getSuggestionValue = (suggestion: any): string => {
    return suggestion[suggestionField];
  };

  const getSuggestions = (value: string, list: any[]) => {
    let count = 0;
    const max = 10;
    return list.filter((suggestion) => {
      const keepName = count < max && suggestion[suggestionField].toLowerCase().includes(value.toLowerCase());
      if (keepName) {
        count += 1;
      }
      return keepName;
    });
  };

  const renderSuggestion = (suggestion: any, { query, isHighlighted }: any) => {
    const matches = match(suggestion[suggestionField], query);
    const parts = parse(suggestion[suggestionField], matches as any);
    return (
      <MenuItem selected={isHighlighted} component="div">
        <div className={css.suggestion}>
          <div>
            {parts.map((part, index) =>
              part.highlight ? (
                <span key={String(index)} className={css.highlightedPart}>
                  {part.text}
                </span>
              ) : (
                <strong key={String(index)} className={css.part}>
                  {part.text}
                </strong>
              ),
            )}
          </div>
        </div>
      </MenuItem>
    );
  };

  const handleSelectSuggestion = (suggestion: any) => {
    onChange({
      target: {
        name: name,
        value: suggestion[suggestionField],
      },
    });
  };

  // const validate = (value: string | null = null) => {
  //   if (suggestionList?.find((m) => m[suggestionField] === value) === undefined) {
  //     if (fnSetError) fnSetError(true);
  //     return false;
  //   } else {
  //     if (fnSetError) fnSetError(false);
  //     return true;
  //   }
  // };

  return (
    <AutoCompleteTextField
      shouldRenderSuggestions={() => suggestionList && suggestionList?.length > 0}
      name={name}
      label={label}
      searchList={suggestionList}
      maxResultsDisplayed={10}
      value={value}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      className={classNames(
        {
          [css.field]: !haveValueOrFocused,
        },
        {
          [css.fieldFocused]: haveValueOrFocused,
        },
      )}
      onBlur={() => setFocused(false)}
      onFocus={() => setFocused(true)}
      variant={variant}
      selectSuggestion={(_event: any, { suggestion }: any) => handleSelectSuggestion(suggestion)}
      onChange={onChange}
      getSuggestions={getSuggestions as any}
      InputLabelProps={{ shrink: false }}
      disabled={disabled}
    />
  );
}

export default MakeAutoComplete;
