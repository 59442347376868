/* eslint-disable jsx-a11y/alt-text */
import React, { CSSProperties } from 'react';
import css from './styles/Comment.module.scss';
import noteIcon from '../../assets/note.svg';
import noteEdit from '../../assets/note-edit.svg';
import noteDelete from '../../assets/note-delete.svg';
import { Note } from 'types/Note';
import LocalAuth from '@shamrock-core/common/authentication/local-authentication';
import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import ConfirmationDialog from './ConfirmationDialog';
import LoadingSpinner from './LoadingSpinner';
import { useTranslation } from 'react-i18next';

interface Props {
  note: Note;
  onDelete: Function;
  onSave: Function;
  showSnackbar: Function;
  editNote: (message: any) => void;
  deleteNote: () => void;
}

export function Comment({ note, onDelete, onSave, showSnackbar, editNote, deleteNote }: Props) {
  const { t } = useTranslation();
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [textAreaHeight, setTextAreaHeight] = React.useState('auto');
  const [isLoading, setIsLoading] = React.useState(false);
  const textAreaStyle: CSSProperties = {
    height: textAreaHeight,
  };
  const [isEdit, setIsEdit] = React.useState(false);
  const [message, setMessage] = React.useState(note.note);

  const isOwner = React.useMemo(() => {
    return LocalAuth.getEmail() === note.createdBy;
  }, [note]);

  React.useEffect(() => {
    setMessage(note.note);
  }, [note]);

  React.useEffect(() => {
    setTextAreaHeight(`${textAreaRef?.current?.scrollHeight ?? +4}px`);
  }, [isEdit]);

  const HeaderIcon = () => (
    <div className={css.headerIcon}>
      <img src={noteIcon} />
    </div>
  );

  const HeaderText = ({ createdOn, createdBy }: { createdOn: string; createdBy: string }) => (
    <div className={css.headerText}>
      <div className={css.name}>{isOwner ? 'Me' : createdBy}</div>
      <div className={css.timestamp}>{createdOn}</div>
    </div>
  );

  const handleOnChange = (event: any) => {
    setMessage(event?.target?.value);
  };

  const handleDialogClose = () => {
    setOpenDeleteDialog(false);
  };
  const isToday = (someDate: any) => {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate() && someDate.getMonth() === today.getMonth() && someDate.getFullYear() === today.getFullYear()
    );
  };
  const isYesterday = (someDate: any) => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return (
      someDate.getDate() === yesterday.getDate() &&
      someDate.getMonth() === yesterday.getMonth() &&
      someDate.getFullYear() === yesterday.getFullYear()
    );
  };

  const createdOn = React.useMemo(() => {
    const date = new Date(note?.createdOn);
    const dateString = date?.toLocaleDateString();
    const timeString = date?.toLocaleTimeString();
    const day = isToday(date) ? t('Today') : isYesterday(date) ? t('Yesterday') : dateString;
    return `${day} at ${timeString}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note]);

  const handleDeleteDialogResult = async () => {
    setOpenDeleteDialog(false);
    setIsLoading(true);
    try {
      await deleteNote();
    } catch (e: any) {
      showSnackbar(e?.response?.data?.toString() || t('Error Deleting Note.'), 'error');
    }
    onDelete(note);
    setIsLoading(false);
  };

  const handleSave = () => {
    setIsLoading(true);
    try {
      editNote(message);
    } catch (e: any) {
      showSnackbar(e?.response?.data?.toString() || t('Error Saving Note.'), 'error');
    }
    setIsLoading(false);
    onSave(note, { ...note, note: message });
    setIsEdit(false);
  };

  const handleDeleteClicked = () => {
    setOpenDeleteDialog(true);
  };

  const getSpinner = () => {
    return <LoadingSpinner size={20} customStyles={{ borderRadius: 10, opacity: 0.7, backgroundColor: '#dddddd' }} />;
  };

  return (
    <div className={css.note}>
      <ConfirmationDialog
        open={openDeleteDialog || false}
        onDialogClose={handleDialogClose}
        onPrimaryActionCompleted={handleDeleteDialogResult}
        title={t('Remove Note?')}
        message={t('Removing a note cannot be undone.')}
      />
      <div className={css.header}>
        <HeaderIcon />
        <HeaderText createdBy={note.createdBy} createdOn={createdOn} />
      </div>
      <div className={css.messageContainer}>
        {isOwner && (
          <>
            {!isEdit && (
              <div className={css.message}>
                {isLoading && getSpinner()}
                <p>
                  {note.note}
                  {note.isEdited && (
                    <strong className={css.edited}>
                      <em> (edited)</em>
                    </strong>
                  )}
                </p>
              </div>
            )}
            {isEdit && (
              <div className={css.editContainer}>
                {isLoading && getSpinner()}
                <textarea
                  className={css.input}
                  value={message}
                  onChange={handleOnChange}
                  maxLength={1000}
                  ref={textAreaRef}
                  style={textAreaStyle}
                />
              </div>
            )}
            <div className={css.actions}>
              {!isEdit && (
                <>
                  <img src={noteEdit} onClick={() => setIsEdit(true)} />
                  <img src={noteDelete} onClick={handleDeleteClicked} />
                </>
              )}
              {isEdit && (
                <>
                  <IconButton className={css.iconButton} onClick={handleSave}>
                    <DoneIcon />
                  </IconButton>
                  <IconButton className={css.iconButton} onClick={() => setIsEdit(false)}>
                    <ClearIcon />
                  </IconButton>
                </>
              )}
            </div>
          </>
        )}
        {!isOwner && (
          <>
            <div className={css.message}>
              <p>
                {note.note}
                {note.isEdited && (
                  <strong className={css.edited}>
                    <em> (edited)</em>
                  </strong>
                )}
              </p>
            </div>
            <div className={css.actions} />
          </>
        )}
      </div>
    </div>
  );
}
