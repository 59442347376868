import { FormControl, InputLabel, MenuItem, Select, FormHelperText } from '@mui/material';
import css from '../trucks/styles/EquipmentAddEdit.module.scss';
import { ReactPropTypes } from 'react';
import { FuelType } from 'types/FuelType';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';
import { getFuelTypeList } from 'store/reducers/appSlice';

interface Props extends Partial<ReactPropTypes> {
  type: FuelType;
  onChange: (fuelType: FuelType) => {};
  readonly?: boolean;
  error?: boolean;
  helperText?: string;
}

export function FuelTypeSelect({ type, onChange, readonly, error, helperText }: Props) {
  const fuelTypes = useAppSelector(getFuelTypeList);

  const handleOnChange = (event: any) => {
    const type = fuelTypes?.find((f: any) => f.id === event.target.value);
    onChange(type as FuelType);
  };
  const { t } = useTranslation();

  return (
    <FormControl variant="filled" className={css.fuelControl} margin={'dense'}>
      <InputLabel classes={{ root: css.labelRoot }}>{t('Fuel Type')}</InputLabel>
      <Select error={error} value={type?.id || ''} onChange={handleOnChange} disabled={readonly}>
        {fuelTypes?.map((fuelType: FuelType) => {
          return (
            <MenuItem classes={{ root: css.item }} key={fuelType.id} value={fuelType.id}>
              {fuelType.name}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText className={css.errorHelper}>{helperText}</FormHelperText>
    </FormControl>
  );
}

export default FuelTypeSelect;
