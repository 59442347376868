import Default from './default';
import Theme1 from './theme1';
import PtTheme from './ptTheme';

import { PaletteThemeProps } from '../../types/theme';
import { PalettesProps } from '@ant-design/colors';
import { ThemeMode, PresetColor } from '../../types/config';

const Theme = (colors: PalettesProps, presetColor: PresetColor, mode: ThemeMode): PaletteThemeProps => {
  switch (presetColor) {
    case 'theme1':
      return Theme1(colors, mode);
    case 'ptTheme':
      return PtTheme(colors, mode);
    default:
      return Default(colors);
  }
};

export default Theme;
