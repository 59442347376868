import React, { useCallback } from 'react';
import css from './styles/TrailerDescriptionModule.module.scss';
import { Trailer } from 'types/Trailer';
import { TextField, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import bindProperty from 'utils/bindProperty';
import NumberFormat from 'react-number-format';
import CustomSelect from 'components/common/CustomSelect';
import { useAppSelector } from 'store';
import { getTrailerTypeList, getTrailerDoorTypeList, getTrailerMakeList } from 'store/reducers/appSlice';
import CustomAutoComplete from 'components/common/CustomAutoComplete';
import * as equipmentService from 'services/equipmentService';
import { EquipmentModel } from 'types/EquipmentModel';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import LoadingSpinner from 'components/common/LoadingSpinner';
import infoIcon from '../../assets/orange-info-icon.svg';

interface Props extends Partial<React.ReactPropTypes> {
  trailer: Trailer;
  handleChange: any;
  currentValidation: any;
  handleMakeSelect: any;
  showSnackbar: Function;
  isVinDecodeEdit: boolean;
}

const TrailerDescriptionModule: React.FC<Props> = ({
  trailer,
  handleChange,
  currentValidation,
  handleMakeSelect,
  showSnackbar,
  isVinDecodeEdit,
}: Props) => {
  const { t } = useTranslation();
  const colors = [t('Blue'), t('White'), t('Gray'), t('Silver'), t('Red'), t('Black'), t('Green'), t('Orange'), t('Other')];
  const typeList = useAppSelector(getTrailerTypeList);
  const doorTypeList = useAppSelector(getTrailerDoorTypeList);
  const makeList = useAppSelector(getTrailerMakeList);
  const [isLoading, setIsLoading] = React.useState(false);
  const [modelList, setModelList] = React.useState<EquipmentModel[]>([]);
  const [inputMake, setInputMake] = React.useState(trailer?.make);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce((newValue) => getModelList(newValue).then(handleMakeSelect(newValue)), 1000),
    [],
  );

  const handleMakeChange = (make: string | undefined) => {
    setInputMake(make);
    debouncedSave(make);
  };

  const getModelList = async (make: string | undefined) => {
    let list: React.SetStateAction<EquipmentModel[]> = [];
    if (makeList?.some((x) => x.makeName === make) && make) {
      setIsLoading(true);
      try {
        const makeId = makeList.find((x) => x.makeName === make)?.makeId;
        const response = await equipmentService.getEquipmentModels('trailer', makeId);
        list = response.data;
      } catch (e) {
        console.error(e);
        showSnackbar(t('An error retrieving equipment models, please try again'), 'error');
      } finally {
        setIsLoading(false);
      }
    }
    setModelList(list);
  };

  return (
    <div className={css.container}>
      {isVinDecodeEdit && (
        <div className={css.vinEditMessage}>
          <img src={infoIcon} width={'14px'} height={'14px'} alt="infoIcon" />
          {` ${t('You are about to edit data')}`}
        </div>
      )}
      {isLoading && <LoadingSpinner customStyles={{ marginTop: -121, marginLeft: -54 }} />}
      <div className={css.row}>
        <CustomSelect
          name="trailerTypeId"
          label={t('Trailer Type')}
          value={bindProperty(trailer?.trailerTypeId)}
          options={typeList}
          onChange={handleChange}
        />
        <TextField
          name="year"
          margin="dense"
          label={t('Year')}
          variant={'filled'}
          type="number"
          inputProps={{ min: '1900', max: '2050' }}
          error={currentValidation.year.hasError}
          helperText={currentValidation.year.message}
          value={bindProperty(trailer?.year)}
          onChange={handleChange}
          onInput={(e: any) => {
            e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 4);
          }}
        />
      </div>
      <div className={css.row}>
        <CustomAutoComplete
          name="make"
          label={t('Make')}
          value={bindProperty(inputMake)}
          variant={'standard'}
          onChange={(input: any) => handleMakeChange(input.target.value)}
          suggestionList={makeList as any}
          suggestionField="makeName"
        />
        <CustomAutoComplete
          name="model"
          label={t('Model')}
          value={bindProperty(trailer?.model)}
          variant={'standard'}
          onChange={handleChange}
          suggestionList={modelList as any}
          suggestionField="modelName"
          disabled={!inputMake || isLoading}
        />
      </div>
      <div className={css.row}>
        <CustomSelect
          name="trailerDoorTypeId"
          label={t('Door Type')}
          value={bindProperty(trailer?.trailerDoorTypeId)}
          options={doorTypeList}
          onChange={handleChange}
        />
        <NumberFormat
          name="numberOfAxles"
          color="primary"
          customInput={TextField}
          margin="dense"
          variant={'filled'}
          label={t('Number of Axles')}
          value={bindProperty(trailer?.numberOfAxles)}
          error={currentValidation.numberOfAxles.hasError}
          helperText={currentValidation.numberOfAxles.message}
          onChange={handleChange}
          decimalScale={0}
          thousandSeparator=","
        />
      </div>
      <div className={css.row}>
        <NumberFormat
          name="unloadedVehicleWeight"
          color="primary"
          customInput={TextField}
          margin="dense"
          variant={'filled'}
          label={t('Unloaded Vehicle Weight')}
          value={bindProperty(trailer?.unloadedVehicleWeight)}
          error={currentValidation.unloadedVehicleWeight.hasError}
          helperText={currentValidation.unloadedVehicleWeight.message}
          onChange={handleChange}
          thousandSeparator=","
          decimalScale={0}
        />
        <NumberFormat
          name="grossVehicleWeight"
          color="primary"
          customInput={TextField}
          margin="dense"
          variant={'filled'}
          label={t('Gross Vehicle Weight')}
          value={bindProperty(trailer?.grossVehicleWeight)}
          error={currentValidation.grossVehicleWeight.hasError}
          helperText={currentValidation.grossVehicleWeight.message}
          onChange={handleChange}
          thousandSeparator=","
          decimalScale={0}
        />
      </div>
      <div className={css.row}>
        <FormControl variant="filled">
          <InputLabel classes={{ root: css.selectLabelRoot }}>Color</InputLabel>
          <Select
            name="color"
            id="cdlClassSelect"
            value={bindProperty(trailer?.color)}
            onChange={handleChange}
            style={{ fontSize: '16px', height: '52px', marginTop: '3px' }}
          >
            {colors?.map((color: string) => {
              return (
                <MenuItem classes={{ root: css.item }} key={color} value={color}>
                  {color}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <NumberFormat
          name="insuranceValue"
          color="primary"
          customInput={TextField}
          margin="dense"
          variant={'filled'}
          label={t('Insurance Value')}
          value={bindProperty(trailer?.insuranceValue)}
          error={currentValidation.insuranceValue.hasError}
          helperText={currentValidation.insuranceValue.message}
          onChange={handleChange}
          thousandSeparator=","
          decimalScale={2}
          prefix={'$'}
        />
      </div>
    </div>
  );
};

export default TrailerDescriptionModule;
