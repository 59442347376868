import css from './styles/ConfirmationDialog.module.scss';
import { Dialog } from '@mui/material';
import React, { ReactPropTypes } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

interface Props extends Partial<ReactPropTypes> {
  onDialogClose: Function;
  onPrimaryActionCompleted: Function;
  open: boolean;
  title?: string;
  message?: string;
  cancelCloseBtnText?: string;
  continueCloseBtnText?: string;
}

function ConfirmationDialog({
  onDialogClose,
  onPrimaryActionCompleted,
  open,
  title,
  message,
  cancelCloseBtnText,
  continueCloseBtnText,
}: Props): JSX.Element {
  const [loading, setLoading] = React.useState(false);

  const handleClose = () => {
    onDialogClose();
  };

  const handlePrimaryAction = async () => {
    try {
      onPrimaryActionCompleted();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby={css.formDialogTitle}
      classes={{ paper: css.dialogContainer }}
      style={{ zIndex: 99999 }}
    >
      {loading && <div className={css.loadingContainer} />}
      {loading && <CircularProgress className={css.loadingSpinner} variant={'indeterminate'} size={60} />}
      <div className={css.content}>
        <div className={css.title}>{title || t('Remove Load?')}</div>
        <div className={css.message}>{message || t('Removing a load cannot be undone.')}</div>
        <div className={css.actionItems}>
          <Button className={css.cancelButton} variant="outlined" onClick={handleClose}>
            {cancelCloseBtnText ? cancelCloseBtnText : `${t('CANCEL')} `}
          </Button>
          <Button className={css.proceedButton} variant="outlined" onClick={handlePrimaryAction}>
            {continueCloseBtnText ? continueCloseBtnText : t('PROCEED')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default ConfirmationDialog;
