import classNames from 'classnames';
import { Fragment } from 'react';
import css from './styles/NavLinkBar.module.scss';
import { useTranslation } from 'react-i18next';
import WarningIcon from '@mui/icons-material/Warning';

export interface NavItem {
  title: string;
  index: number;
}

interface Props {
  currentIndex: number;
  items: NavItem[];
  handleClick: Function;
  warningTab?: number;
  warningTabs?: number[];
}

function NavLinkBar({ currentIndex, items, handleClick, warningTab, warningTabs }: Props) {
  const { t } = useTranslation();
  return (
    <Fragment>
      {items.map((item) => (
        <div
          key={item.index}
          className={classNames(
            css.navItem,
            { [css.active]: currentIndex === item.index },
            {
              [css.warning]:
                item.index === warningTab ||
                (warningTabs && warningTabs.length > 0 && warningTabs.find((tab) => tab === item.index) !== undefined),
            },
          )}
          onClick={() => handleClick(item.index)}
        >
          {t(item.title)}
          {(item.index === warningTab ||
            (warningTabs && warningTabs.length > 0 && warningTabs.find((tab) => tab === item.index) !== undefined)) &&
            currentIndex !== item.index && <WarningIcon />}
        </div>
      ))}
    </Fragment>
  );
}

export default NavLinkBar;
