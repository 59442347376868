/* eslint-disable jsx-a11y/alt-text */
import React, { ReactPropTypes } from 'react';
import css from './styles/DualIconButton.module.scss';

interface Props extends Partial<ReactPropTypes> {
  defaultImage: string;
  hoveredImage: string;
  onClick: any;
  className?: string;
}

const DualIconButton: React.FC<Props> = ({ defaultImage, hoveredImage, onClick, className }: Props) => {
  const [hovered, setHovered] = React.useState(false);

  return (
    <div className={className}>
      <img
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClick}
        className={css.button}
        src={!hovered ? defaultImage : hoveredImage}
      />
    </div>
  );
};

export default DualIconButton;
