import { isNullOrWhitespace } from '../utils/stringUtils';

export class BusinessPartner {
  id: number;
  companyId: number;
  firstName: string;
  lastName: string;
  fullName: string;
  businessPartnerName: string;
  email: string;
  phoneNumber: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  profileImageKey: string;
  transactionKey: string;
  isActive: boolean;
  types: { id: number; name?: string }[];
  isOwnerOperator?: boolean;

  constructor(isActive: boolean = true) {
    this.isActive = isActive;
  }

  static getInitialNameLetters(businessPartner: BusinessPartner) {
    const { firstName, lastName } = businessPartner;
    return isNullOrWhitespace(lastName)
      ? firstName
        ? firstName.substring(0, 1)
        : ''
      : firstName.substring(0, 1) + lastName.substring(0, 1);
  }
}
