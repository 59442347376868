import React, { ReactPropTypes } from 'react';
import css from './styles/ExpirationDatesModule.module.scss';
import { Trailer } from 'types/Trailer';
import bindProperty from 'utils/bindProperty';
import CustomDatePicker from 'components/common/CustomDatePicker';
import { useTranslation } from 'react-i18next';
import { epochToDate } from 'utils/stringUtils';
import infoIcon from '../../assets/orange-info-icon.svg';

interface Props extends Partial<ReactPropTypes> {
  equipment: Trailer;
  handleDateChange: any;
  currentValidation: any;
  isVinDecodeEdit: boolean;
}

const ExpirationDatesModule: React.FC<Props> = ({ equipment, handleDateChange, currentValidation, isVinDecodeEdit }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={css.container}>
      {isVinDecodeEdit && (
        <div className={css.vinEditMessage}>
          <img src={infoIcon} width={'14px'} height={'14px'} alt="infoIcon" />
          {` ${t('You are about to edit data')}`}
        </div>
      )}
      <div className={css.row}>
        <CustomDatePicker
          label={t('DOT Inspection Expiration Date')}
          value={bindProperty(epochToDate(equipment?.dotInspectionExpirationDate))}
          onChange={(value: moment.Moment | null) => {
            handleDateChange('dotInspectionExpirationDate', value);
          }}
          validation={currentValidation.dotInspectionExpirationDate}
        />
        <CustomDatePicker
          label={t('Plates Expiration Date')}
          value={bindProperty(epochToDate(equipment?.licensePlateExpirationDate))}
          onChange={(value: moment.Moment | null) => {
            handleDateChange('licensePlateExpirationDate', value);
          }}
          validation={currentValidation.licensePlateExpirationDate}
        />
      </div>
      <div className={css.row}>
        <CustomDatePicker
          label={t('Insurance Expiration Date')}
          value={bindProperty(epochToDate(equipment?.insuranceExpirationDate))}
          onChange={(value: moment.Moment | null) => {
            handleDateChange('insuranceExpirationDate', value);
          }}
          validation={currentValidation.insuranceExpirationDate}
        />
      </div>
    </div>
  );
};

export default ExpirationDatesModule;
