import React, { ReactPropTypes } from 'react';
import { Switch } from '@mui/material';

const styles = {
  root: { width: 61 },
  iOSSwitchBase: {
    padding: '9px !important',
    color: '#ffffff !important',
    '&$iOSDisabled': {
      color: '#bdbdbd !important',
      '&$iOSChecked': {
        color: '#bdbdbd  !important',
      },
    },
    '&$iOSChecked': {
      color: '#FFFFFF !important',
      '& + $iOSBar': {
        backgroundColor: '#0091ea',
      },
    },
  },
  iOSChecked: {
    transform: 'translateX(15px) !important',
    '& + $iOSBar': {
      opacity: 1,
      border: 'none',
    },
  },
  iOSBar: {
    borderRadius: 13,
    width: 48,
    height: 26,
    marginTop: -5,
    marginLeft: -6,
    backgroundColor: '#CCCCCC',
    opacity: '1 !important',
  },
  iOSIcon: {
    width: 22,
    height: 22,
  },
  iOSIconChecked: {},
  iOSDisabled: {
    '&$iOSSwitchBase': {
      '& + $iOSBar': {
        backgroundColor: '#EEEEEE',
        opacity: 1,
      },
    },
  },
};

export interface CPSwitchProps extends Partial<ReactPropTypes> {
  classes?: any;
  onChange?: (event: React.ChangeEvent, checked: boolean) => void;
  onClick?: (event: React.MouseEvent) => void;
  checked?: boolean;
  disabled?: boolean;
  name?: string;
  value?: boolean;
}

const CPSwitch: React.FC<CPSwitchProps> = (props) => {
  const { onChange, onClick, checked, disabled, name, value } = props;
  const classes = { ...props.classes, ...styles };
  return (
    <Switch
      classes={{
        root: classes.root,
        switchBase: classes.iOSSwitchBase,
        track: classes.iOSBar as any,
        thumb: classes.iOSIcon,
        checked: classes.iOSChecked,
        disabled: classes.iOSDisabled,
      }}
      size={'medium'}
      name={name}
      disableRipple={true}
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      onClick={onClick}
      value={value}
    />
  );
};

export default CPSwitch;
