import React, { useState } from 'react';
import css from './styles/EquipmentAddEdit.module.scss';
import { Truck } from 'types/Truck';
import { Trailer } from 'types/Trailer';
import { validatePartner, validateTruck, validateTrailer } from 'utils/validators';
import * as equipmentService from 'services/equipmentService';
import { FuelType } from 'types/FuelType';
import FirebaseAnalytics from 'utils/firebaseAnalytics';
import CloverThemeProvider from 'shamrock-clover-ui/dist/clover/Theme/ThemeProvider';
import { Dialog } from 'shamrock-clover-ui/dist/clover/components/Dialog/Dialog';
import classNames from 'classnames';
import { Button } from 'shamrock-clover-ui/dist/clover/components/Button/Button';
import ConfirmationDialog from 'components/common/ConfirmationDialog';
import IdentificationModule from './IdentificationModule';
import TruckDescriptionModule from './TruckDescriptionModule';
import TruckBrandModule from './TruckBrandModule';
import BPOwnerModule from './BPOwnerModule';
import FilesModule from 'components/common/FilesModule';
import { deepEqual } from '../../utils/objectUtils';
import UnsavedChangesDialog from 'components/common/UnsavedChangesDialog';
import { Guid } from 'guid-typescript';
import NavLinkBar from 'components/common/NavLinkBar';
import CommentModule from 'components/common/CommentModule';
import SaveAndContinueDialog from 'components/common/SaveContinueDialog';
import LoadingSpinner from 'components/common/LoadingSpinner';
import { BusinessPartner } from 'types/BusinessPartner';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import {
  addTruckToList,
  getTmsCompanyId,
  getTrucks,
  updateTruckInList,
  updateTrailerInList,
  addTrailerToList,
  getTrailers,
} from 'store/reducers/appSlice';
import { FormControl, MenuItem, Select } from '@mui/material';
import { Equipment } from 'types/Equipment';
import ExpirationDatesModule from './ExpirationDatesModule';
import TrailerDescriptionModule from './TrailerDescriptionModule';
import moment from 'moment';

export interface EquipmentAddEditProps {
  equipment: Truck | Trailer;
  handleClose: any;
  isNew: boolean;
  trucks?: Truck[];
  trailers?: Trailer[];
  isOpen: boolean;
  activeIndex: number;
  setEquipmentStatus: Function;
  showSnackbar: Function;
  handleOpenVinDecode: () => void;
  usedVinDecode: boolean;
  handleOnNewSave: Function;
  usedVins: any;
  onSetVinSearchUsed: Function;
}

const EquipmentAddEdit = ({
  equipment,
  handleClose,
  isNew,
  trucks,
  trailers,
  isOpen,
  activeIndex,
  setEquipmentStatus,
  showSnackbar,
  handleOpenVinDecode,
  usedVinDecode,
  handleOnNewSave,
  usedVins,
  onSetVinSearchUsed,
}: EquipmentAddEditProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const tmsCompanyId = useAppSelector(getTmsCompanyId);
  const [editedEquipment, setEditedEquipment] = React.useState(equipment);
  const [isLoading, setIsLoading] = React.useState(false);
  const [tabIndex, setTabIndex] = React.useState(activeIndex > -1 ? activeIndex : 0);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openUnsavedDialog, setOpenUnsavedDialog] = React.useState(false);
  const [warningTab, setWarningTab] = React.useState<number | undefined>(undefined);
  const [openSaveAndContinueDialog, setOpenSaveAndContinueDialog] = React.useState(false);
  const [newPartner, setNewPartner] = React.useState(new BusinessPartner(true));
  const [blockOutsideClose, setBlockOutsideClose] = React.useState(false);
  const phoneTest = new RegExp(/^(\d{10})$/);
  const canSavePartner = newPartner.firstName && newPartner.firstName.length > 0 && phoneTest.test(newPartner.phoneNumber);
  const [radioSelection, setRadioSelection] = React.useState(equipment?.businessPartnerId ? 'select' : 'company');
  const [ownerPartnerEdited, setOwnerPartnerEdited] = useState(false);
  const validId = editedEquipment?.id;
  const isTruck = editedEquipment.equipmentType === 'truck';
  const isTrailer = editedEquipment.equipmentType === 'trailer';
  const [changeEquipmentTypeModal, setChangeEquipmentTypeModal] = React.useState(false);
  const getBrandOrDatesLabel = () => {
    return isTruck ? t('Brand') : t('Expiration Dates');
  };

  const numericFields = ['year', 'numberOfAxles', 'unloadedVehicleWeight', 'grossVehicleWeight', 'insuranceValue'];

  const barItems = [
    { title: t('Identification'), index: 0, link: '#' },
    { title: t('Owner'), index: 1, link: '#' },
    { title: t('Description'), index: 2, link: '#' },
    { title: getBrandOrDatesLabel(), index: 3, link: '#' },
    { title: t('Files'), index: 4, link: '#' },
    { title: t('Comments'), index: 5, link: '#' },
  ];

  const getDefaultValidationState = () => ({
    unitNumber: { hasError: false, message: '' },
    licensePlateNumber: { hasError: false, message: '' },
    vehicleIdentificationNumber: { hasError: false, message: '' },
    year: { hasError: false, message: '' },
    licensePlateState: { hasError: false, message: '' },
    state: { hasError: false, message: '' },
    partnerFirstName: { hasError: false, message: '' },
    partnerEmail: { hasError: false, message: '' },
    partnerPhone: { hasError: false, message: '' },
    numberOfAxles: { hasError: false, message: '' },
    unloadedVehicleWeight: { hasError: false, message: '' },
    grossVehicleWeight: { hasError: false, message: '' },
    insuranceValue: { hasError: false, message: '' },
    dotInspectionExpirationDate: { hasError: false, message: '' },
    licensePlateExpirationDate: { hasError: false, message: '' },
    insuranceExpirationDate: { hasError: false, message: '' },
  });
  const [currentValidation, setCurrentValidation] = React.useState<any>(getDefaultValidationState);
  const equipmentToCompare = isNew ? { ...editedEquipment, equipmentType: 'truck' } : editedEquipment;
  const equipmentNotEdited = equipment && editedEquipment && deepEqual(equipmentToCompare, equipment) && !ownerPartnerEdited;
  const hasErrors = Object.values(currentValidation).some((v: any) => v.hasError);

  /**
   * This function is used to force equipment type to be Trailer
   * Which is needed to access the numberOfAxles and unloadedVehicleWeight
   * Which is needed to determine the isVinEdit value
   */
  const toTrailer = (equipment: Truck | Trailer): equipment is Trailer => {
    return (equipment as Trailer).numberOfAxles !== undefined && (equipment as Trailer).unloadedVehicleWeight !== undefined;
  };
  const isVinEdit =
    (editedEquipment.make !== null && editedEquipment.make !== equipment.make) ||
    (editedEquipment.model !== null && editedEquipment.model !== equipment.model) ||
    (editedEquipment.year !== null && editedEquipment.year !== equipment.year) ||
    (toTrailer(editedEquipment) &&
      toTrailer(equipment) &&
      editedEquipment.numberOfAxles !== null &&
      editedEquipment.numberOfAxles !== equipment.numberOfAxles) ||
    (editedEquipment.vehicleIdentificationNumber !== null &&
      editedEquipment.vehicleIdentificationNumber !== equipment.vehicleIdentificationNumber);

  const isVinDecodeEdit = usedVinDecode && isVinEdit;

  const getEquipmentNumber = (equipment: Equipment) => {
    if (isTruck) {
      return (equipment as Truck).truckNumber;
    } else if (isTrailer) {
      return (equipment as Trailer).trailerNumber;
    }
    return '';
  };

  React.useEffect(() => {
    setEditedEquipment(equipment);
    setCurrentValidation(getDefaultValidationState());
  }, [equipment]);

  React.useEffect(() => {
    setTabIndex(activeIndex);
  }, [activeIndex]);

  const handleSave = async (closeOnSave = true, profileImageKey = undefined) => {
    let equipmentToValidate = {
      ...(editedEquipment as any),
      companyId: tmsCompanyId,
      transactionKey: editedEquipment.transactionKey ? editedEquipment.transactionKey : Guid.create().toString(),
      profileImageKey: profileImageKey !== undefined ? profileImageKey : equipment?.profileImageKey ? equipment.profileImageKey : undefined,
    };
    let validationForPartner = { valid: true };
    if (radioSelection === 'create') {
      validationForPartner = validatePartner(newPartner);
    }
    const equipmentValidationObject = isTruck ? validateTruck(equipmentToValidate) : validateTrailer(equipmentToValidate);
    if (equipmentValidationObject?.valid) {
      const foundEquipment = isTruck
        ? trucks?.find((t) => t.truckNumber === equipmentToValidate.truckNumber)
        : trailers?.find((t) => t.trailerNumber === equipmentToValidate.trailerNumber);
      if (foundEquipment !== undefined && foundEquipment.id !== equipmentToValidate.id) {
        equipmentValidationObject.valid = false;
        equipmentValidationObject.existingNumber = true;
      }
      if (
        usedVins.includes(equipmentToValidate.vehicleIdentificationNumber) &&
        equipmentToValidate.vehicleIdentificationNumber !== equipment?.vehicleIdentificationNumber
      ) {
        equipmentValidationObject.valid = false;
        equipmentValidationObject.existingVehicleIdentificationNumber = true;
      }
    }

    if (equipmentValidationObject?.valid && validationForPartner?.valid) {
      try {
        if (canSavePartner) equipmentToValidate = await handlePartnerSave(equipmentToValidate);

        setIsLoading(true);

        const { data }: any = isTruck
          ? await equipmentService.saveTruck(equipmentToValidate)
          : await equipmentService.saveTrailer(equipmentToValidate);

        if (data.businessPartnerId) {
          const newOwner: any = (await equipmentService.getBusinessPartner(data.businessPartnerId)).data;
          data.ownerName = newOwner.lastName ? newOwner.fullName : newOwner.firstName;
          data.city = newOwner.city;
          data.address1 = newOwner.address1;
          data.address2 = newOwner.address2;
          data.state = newOwner.state;
          data.postalCode = newOwner.postalCode;
        }

        FirebaseAnalytics.sendEvent(
          FirebaseAnalytics.EVENTS.SAVE,
          FirebaseAnalytics.MODULES.ENTERPRISE,
          FirebaseAnalytics.PAGES.ADD_EQUIPMENT,
          {
            label: FirebaseAnalytics.LABELS.SAVE,
          },
        );
        showSnackbar(t('Your information has been saved'), 'success');
        handleEquipmentSave(data, isNew, closeOnSave);
        setWarningTab(undefined);
      } catch (response: any) {
        const errors = response.data;
        let validation = getDefaultValidationState();
        if (errors && errors.length > 0 && (errors[0].key === 'truckNumber' || errors[0].key === 'trailerNumber')) {
          errors.forEach((error: any) => {
            let key = error.key as string;
            if (key === 'truckNumber' || key === 'trailerNumber') {
              key = 'unitNumber';
            }
            validation = { ...validation, [key]: { hasError: true, message: error.message } };
          });
          setCurrentValidation(validation);
          invalidFieldsMessage(validation);
        } else {
          showSnackbar(t('An error occurred while saving your information'), 'error');
        }
      }
    } else {
      handleSetValidationMessages(equipmentValidationObject, validationForPartner);
    }
    setIsLoading(false);
  };

  const handleSetValidationMessages = (equipmentValidationObject: any, partnerValidationObject: any) => {
    const validation = getDefaultValidationState();
    if (!equipmentValidationObject?.valid) {
      if (equipmentValidationObject?.existingNumber) {
        validation['unitNumber']['message'] = t('Unit number already exists');
        validation['unitNumber']['hasError'] = true;
      }
      if (equipmentValidationObject?.missingNumber) {
        validation['unitNumber']['message'] = t('Unit number is required');
        validation['unitNumber']['hasError'] = true;
      }
      if (equipmentValidationObject?.invalidVehicleIdentificationNumber) {
        validation['vehicleIdentificationNumber']['message'] = t('VIN is invalid');
        validation['vehicleIdentificationNumber']['hasError'] = true;
      }
      if (equipmentValidationObject?.existingVehicleIdentificationNumber) {
        validation['vehicleIdentificationNumber']['message'] = t('VIN already used by another equipment');
        validation['vehicleIdentificationNumber']['hasError'] = true;
      }
    }

    if (!partnerValidationObject?.valid) {
      if (partnerValidationObject?.missingFirstName) {
        validation['partnerFirstName']['message'] = t('Business Partner first name is required');
        validation['partnerFirstName']['hasError'] = true;
      }
      if (partnerValidationObject?.missingPhoneNumber) {
        validation['partnerPhone']['message'] = t('Business Partner phone number is required');
        validation['partnerPhone']['hasError'] = true;
      }
    }

    setCurrentValidation(validation);
    invalidFieldsMessage(validation);
  };

  const invalidFieldsMessage = (validations: any) => {
    showSnackbar(t('There are some invalid fields, please verify them'), 'error');
    if (
      validations.licensePlateNumber.hasError ||
      validations.licensePlateState.hasError ||
      validations.unitNumber.hasError ||
      validations.state.hasError ||
      validations.vehicleIdentificationNumber.hasError ||
      validations.year.hasError
    ) {
      setWarningTab(0);
    }
    if (validations.partnerEmail.hasError || validations.partnerFirstName.hasError || validations.partnerPhone.hasError) {
      setWarningTab(1);
    }
  };

  const handlePartnerSave = async (equipment: Truck | Trailer) => {
    try {
      if (
        currentValidation.partnerFirstName.hasError ||
        currentValidation.partnerEmail.hasError ||
        currentValidation.partnerPhone.hasError
      ) {
        setWarningTab(1);
        throw new Error('Partner Invalid');
      }
      setIsLoading(true);

      let partner: BusinessPartner = { ...newPartner };
      partner.types = [{ id: 4 }, { id: 1 }];
      partner.isOwnerOperator = true;

      let response = await equipmentService.saveDriver(partner, !equipment.businessPartnerId);
      if (!equipment.businessPartnerId) {
        setEditedEquipment({ ...editedEquipment, businessPartnerId: response.id });
        setNewPartner({ ...newPartner, id: response.id });
      }

      return { ...equipment, businessPartnerId: response.id };
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEquipmentSave = (equipmentToSave: Truck | Trailer, isNew: boolean, closeOnSave: boolean) => {
    if (isTruck) {
      if (isNew) {
        dispatch(addTruckToList(equipmentToSave));
      } else {
        dispatch(
          updateTruckInList({ ...equipmentToSave, cachedImageUrl: equipmentToSave.profileImageKey ? equipment.cachedImageUrl : undefined }),
        );
      }
    } else {
      if (isNew) {
        dispatch(addTrailerToList(equipmentToSave));
      } else {
        dispatch(
          updateTrailerInList({
            ...equipmentToSave,
            cachedImageUrl: equipmentToSave.profileImageKey ? equipment.cachedImageUrl : undefined,
          }),
        );
      }
    }

    if (closeOnSave && !isNew) {
      setTimeout(() => {
        handleClose(equipmentToSave);
      }, 2000);
    } else if (closeOnSave && isNew) {
      setOwnerPartnerEdited(false);
      setOpenSaveAndContinueDialog(true);
      setEquipmentStatus(false);
      const savedEquipment = {
        ...equipmentToSave,
        equipmentType: isTruck ? 'truck' : 'trailer',
      };
      setEditedEquipment(savedEquipment);
      handleOnNewSave(savedEquipment);
    }
  };

  const handleEquipmentRemove = async () => {
    let canDelete = false;
    setIsLoading(true);
    try {
      if (isTruck) {
        await equipmentService.canDeleteTruck(equipment.id as number);
      } else {
        await equipmentService.canDeleteTrailer(equipment.id as number);
      }
      canDelete = true;
    } catch (response) {
      showSnackbar(t('Equipment in use and cannot be deleted'), 'error');
    }
    if (canDelete) {
      try {
        if (isTruck) {
          await equipmentService.deleteTruck(equipment.id as number);
          dispatch(getTrucks());
        } else {
          await equipmentService.deleteTrailer(equipment.id as number);
          dispatch(getTrailers());
        }
        showSnackbar(t('Equipment has been removed'), 'success');
        handleClose();
      } catch (response) {
        showSnackbar(t('Unknown error removing equipment'), 'error');
      }
    }
    setIsLoading(false);
  };

  const handleActiveChange = (event: any) => {
    const newEquipment = { ...editedEquipment } as Equipment;
    newEquipment.isActive = event.target.value === 'true';
    setEditedEquipment(newEquipment);
  };

  const validate = (name: any, value: any) => {
    switch (name) {
      case 'licensePlateNumber':
        if ((value.length > 8 || value.length < 6) && value.length !== 0) {
          setCurrentValidation({
            ...currentValidation,
            licensePlateNumber: { hasError: true, message: t('Plate # must be between 6 and 8 characters') },
          });
        } else {
          setCurrentValidation({
            ...currentValidation,
            licensePlateNumber: { hasError: false, message: '' },
          });
          setWarningTab(undefined);
        }
        break;
      case 'vehicleIdentificationNumber':
        if (value.length < 17 && value.length !== 0) {
          setCurrentValidation({
            ...currentValidation,
            vehicleIdentificationNumber: { hasError: true, message: t('VIN must have 17 characters') },
          });
        } else {
          setCurrentValidation({
            ...currentValidation,
            vehicleIdentificationNumber: { hasError: false, message: '' },
          });
          setWarningTab(undefined);
        }
        break;
      case 'year':
        if (value.length !== 4 && value.length !== 0) {
          setCurrentValidation({
            ...currentValidation,
            year: { hasError: true, message: t('Year must be 4 characters') },
          });
          setWarningTab(isTruck ? 3 : 2);
        } else {
          setCurrentValidation({
            ...currentValidation,
            year: { hasError: false, message: '' },
          });
          setWarningTab(undefined);
        }
        break;
      case 'numberOfAxles':
      case 'unloadedVehicleWeight':
      case 'grossVehicleWeight':
      case 'insuranceValue':
        if (value.trim() === '') {
          setCurrentValidation({
            ...currentValidation,
            [name]: { hasError: false, message: '' },
          });
          setWarningTab(undefined);
        } else {
          const numValue = Number(value.replace('$', '').replaceAll(',', ''));
          if (!numValue || numValue < 0) {
            setCurrentValidation({
              ...currentValidation,
              [name]: { hasError: true, message: t('Value must be positive') },
            });
            setWarningTab(2);
          } else if (numValue > 2147483647) {
            setCurrentValidation({
              ...currentValidation,
              [name]: { hasError: true, message: t('Value must be less than 2,147,483,647') },
            });
            setWarningTab(2);
          } else {
            setCurrentValidation({
              ...currentValidation,
              [name]: { hasError: false, message: '' },
            });
            setWarningTab(undefined);
          }
        }
        break;
      case 'dotInspectionExpirationDate':
      case 'licensePlateExpirationDate':
      case 'insuranceExpirationDate':
        if (value && !value.isValid()) {
          setCurrentValidation({
            ...currentValidation,
            [name]: { hasError: true, message: t('Invalid Date') },
          });
          setWarningTab(3);
        } else {
          setCurrentValidation({
            ...currentValidation,
            [name]: { hasError: false, message: '' },
          });
          setWarningTab(undefined);
        }
        break;
      default:
        setCurrentValidation({
          ...currentValidation,
          [name]: { hasError: false, message: '' },
        });
        setWarningTab(undefined);
        break;
    }
  };

  const handleChange = (event: any) => {
    let { name, value } = event.target;
    validate(name, value);

    if (numericFields.includes(name)) {
      value = Number(value.replace('$', '').replaceAll(',', ''));
    }

    if (name === 'unitNumber') {
      name = isTruck ? 'truckNumber' : 'trailerNumber';
    }

    if (name === 'year' && value === 0 && !isTruck) {
      value = null;
    }

    setEditedEquipment({ ...editedEquipment, [name]: value });
  };

  const handleMakeSelect = (make: string | undefined) => {
    if (make && make?.length > 0) {
      setEditedEquipment({ ...editedEquipment, make: make });
    }
  };

  const handleDateChange = (name: string, value: moment.Moment) => {
    validate(name, value);
    if (value?.isValid() || value === null) {
      const epochDate = value?.valueOf();
      setEditedEquipment({ ...editedEquipment, [name]: epochDate });
    }
  };

  const handleFuelTypeChange = (fuelType: FuelType) => {
    setEditedEquipment({ ...editedEquipment, fuelType });
  };

  const closeDialog = () => {
    if (equipmentNotEdited && canSavePartner === undefined) {
      handleClose();
    } else {
      setOpenUnsavedDialog(true);
    }
  };

  const handleActionButtonValidation = () => {
    if (radioSelection === 'create') {
      if (editedEquipment?.ownerName) {
        setEditedEquipment({ ...editedEquipment, ownerName: undefined });
      }
      return canSavePartner;
    } else if (radioSelection === 'select') {
      if (currentValidation.partnerPhone.hasError) {
        setWarningTab(undefined);
        setCurrentValidation({ ...currentValidation, partnerPhone: { hasError: false, message: '' } });
      }
      return !equipmentNotEdited && !!editedEquipment?.ownerName;
    } else {
      return !equipmentNotEdited;
    }
  };

  const handleTabChangeAnalytics = (tab: number) => {
    setTabIndex(tab);
    const title = barItems.find((x) => x.index === tab)?.title;
    FirebaseAnalytics.sendEvent(
      FirebaseAnalytics.EVENTS.CLICK,
      FirebaseAnalytics.MODULES.ENTERPRISE,
      FirebaseAnalytics.PAGES.ADD_EQUIPMENT,
      {
        label: title,
      },
    );
  };

  const handleEquipmentTypeChange = (event: any) => {
    const { value } = event.target;
    const hasFieldChanges = !deepEqual({ ...editedEquipment, equipmentType: value }, { ...equipment, equipmentType: value });
    const hasExistingInput = Object.keys(editedEquipment).some((field) => field !== 'equipmentType' && !!(editedEquipment as any)[field]);

    if ((usedVinDecode || hasFieldChanges) && hasExistingInput) {
      setChangeEquipmentTypeModal(true);
    } else {
      setEditedEquipment({ ...editedEquipment, equipmentType: value });
      FirebaseAnalytics.sendEvent(
        FirebaseAnalytics.EVENTS.SELECT,
        FirebaseAnalytics.MODULES.ENTERPRISE,
        FirebaseAnalytics.PAGES.ADD_EQUIPMENT,
        {
          label: value === 'truck' ? FirebaseAnalytics.LABELS.TRUCK : FirebaseAnalytics.LABELS.TRAILER,
        },
      );
    }
  };

  const handleConfirmEquipmentTypeChange = () => {
    const fuelType: FuelType = { id: 1 };
    const newEquipment: Equipment = {
      equipmentType: editedEquipment.equipmentType === 'truck' ? 'trailer' : 'truck',
      fuelType: fuelType,
      isActive: true,
    };
    FirebaseAnalytics.sendEvent(
      FirebaseAnalytics.EVENTS.SELECT,
      FirebaseAnalytics.MODULES.ENTERPRISE,
      FirebaseAnalytics.PAGES.ADD_EQUIPMENT,
      {
        label: newEquipment.equipmentType === 'truck' ? FirebaseAnalytics.LABELS.TRUCK : FirebaseAnalytics.LABELS.TRAILER,
      },
    );
    setEditedEquipment(newEquipment as any);
    onSetVinSearchUsed(false);
    setChangeEquipmentTypeModal(false);
  };

  const isValidInput = handleActionButtonValidation();

  const dialogContent = (
    <div className={css.contentContainer}>
      {isLoading && <LoadingSpinner customStyles={{ marginTop: -121, marginLeft: -54 }} />}
      <div className={css.heading}>{isNew ? t('Add Equipment') : getEquipmentNumber(editedEquipment)}</div>
      <div className={css.heading2}>
        <label className={css.typeLabel}>Equipment Type</label>
        <FormControl className={css.typeEditor} variant="filled">
          <Select
            name="equipmentType"
            value={editedEquipment.equipmentType}
            onChange={handleEquipmentTypeChange}
            disabled={!isNew}
            MenuProps={{
              classes: {
                list: css.typeOption,
                root: css.typeRoot,
              },
            }}
          >
            <MenuItem value={'truck'}>Truck</MenuItem>
            <MenuItem value={'trailer'}>Trailer</MenuItem>
          </Select>
        </FormControl>
        {isNew && (
          <p className={css.link} onClick={handleOpenVinDecode}>
            Add equipment using VIN
          </p>
        )}
      </div>
      <div className={css.tabBarContainer}>
        <NavLinkBar
          items={barItems}
          currentIndex={tabIndex}
          handleClick={(index: number) => handleTabChangeAnalytics(index)}
          warningTab={warningTab}
        />
      </div>
      <div className={css.scrollable}>
        <div className={css.hiddenDataShown}>
          <div hidden={tabIndex !== 0} className={css.tab}>
            <IdentificationModule
              equipment={editedEquipment}
              handleChange={handleChange}
              currentValidation={currentValidation}
              handleFuelTypeChange={handleFuelTypeChange}
              handleActiveChange={handleActiveChange}
              handleSave={(profileImageKey?: string) => handleSave(false, profileImageKey as any)}
              showSnackbar={showSnackbar}
              setValidation={setCurrentValidation}
              getEquipmentNumber={getEquipmentNumber}
              isTruck={isTruck}
              setBlockOutsideClose={setBlockOutsideClose}
              isVinDecodeEdit={isVinDecodeEdit}
            />
          </div>
          <div hidden={tabIndex !== 1} className={css.tab}>
            <BPOwnerModule
              equipment={editedEquipment}
              reduxEquipment={equipment}
              setEquipment={(equipment: Truck | Trailer) => setEditedEquipment(equipment)}
              currentValidation={currentValidation}
              setValidation={setCurrentValidation}
              newPartner={newPartner}
              setNewPartner={(partner: BusinessPartner) => {
                setNewPartner(partner);
                setOwnerPartnerEdited(true);
              }}
              setWarningTab={setWarningTab}
              radioSelection={radioSelection}
              setRadioSelection={setRadioSelection}
              isVinDecodeEdit={isVinDecodeEdit}
            />
          </div>
          <div hidden={tabIndex !== 2} className={css.tab}>
            {isTruck && (
              <TruckDescriptionModule truck={editedEquipment} handleTruckChange={handleChange} isVinDecodeEdit={isVinDecodeEdit} />
            )}
            {!isTruck && (
              <TrailerDescriptionModule
                trailer={editedEquipment}
                handleChange={handleChange}
                currentValidation={currentValidation}
                handleMakeSelect={handleMakeSelect}
                showSnackbar={showSnackbar}
                isVinDecodeEdit={isVinDecodeEdit}
              />
            )}
          </div>
          <div hidden={tabIndex !== 3} className={css.tab}>
            {isTruck && (
              <TruckBrandModule
                equipment={editedEquipment}
                handleChange={handleChange}
                currentValidation={currentValidation}
                isVinDecodeEdit={isVinDecodeEdit}
              />
            )}
            {!isTruck && (
              <ExpirationDatesModule
                equipment={editedEquipment}
                handleDateChange={handleDateChange}
                currentValidation={currentValidation}
                isVinDecodeEdit={isVinDecodeEdit}
              />
            )}
          </div>
          <div hidden={tabIndex !== 4} className={classNames(css.tab, css.moduleContainer, validId && css.filesModuleContainer)}>
            <FilesModule
              item={editedEquipment}
              setItem={(truck: Truck) => setEditedEquipment(truck)}
              saveItem={() => handleSave(false)}
              setIsLoading={setIsLoading}
              showSnackbar={showSnackbar}
            />
          </div>
          <div hidden={tabIndex !== 5} className={css.tab}>
            <CommentModule isTruck={isTruck} equipment={editedEquipment} showSnackbar={showSnackbar} />
          </div>
        </div>
      </div>
      <ConfirmationDialog
        open={changeEquipmentTypeModal || false}
        onDialogClose={() => setChangeEquipmentTypeModal(false)}
        onPrimaryActionCompleted={handleConfirmEquipmentTypeChange}
        title={t('Change Equipment Type?')}
        message={t('Changing equipment type cannot be undone')}
      ></ConfirmationDialog>
      <ConfirmationDialog
        open={openDeleteDialog || false}
        onDialogClose={() => setOpenDeleteDialog(false)}
        onPrimaryActionCompleted={() => {
          handleEquipmentRemove();
          setOpenDeleteDialog(false);
        }}
        title={t('Remove Unit?')}
        message={t('Removing a unit cannot be undone.')}
      />
      <div className={css.footer}>
        <Button buttonStyle="text" color="red" disabled={isNew} onClick={() => setOpenDeleteDialog(true) as any}>
          {t('Remove Unit')}
        </Button>
        <Button onClick={handleSave} disabled={hasErrors || !isValidInput}>
          Save
        </Button>
      </div>
      <UnsavedChangesDialog
        open={openUnsavedDialog || false}
        onDialogClose={() => setOpenUnsavedDialog(false)}
        onPrimaryActionCompleted={() => {
          handleClose();
          setOpenUnsavedDialog(false);
        }}
      />
      <SaveAndContinueDialog
        onSaveAndClose={() => handleClose()}
        onSaveAndContinue={() => setOpenSaveAndContinueDialog(false)}
        open={openSaveAndContinueDialog}
      />
    </div>
  );

  return (
    <CloverThemeProvider>
      <Dialog open={isOpen} content={dialogContent} onClose={closeDialog} closeOnBackdropClick={!blockOutsideClose} />
    </CloverThemeProvider>
  );
};

export default EquipmentAddEdit;
