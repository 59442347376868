import css from './styles/VinModal.module.scss';
import LoadingSpinner from 'components/common/LoadingSpinner';
import { ChangeEvent, useState } from 'react';
import * as equipmentService from 'services/equipmentService';
import { Dialog, TextField, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { VinError } from 'types/Vin';
import FirebaseAnalytics from 'utils/firebaseAnalytics';
import { useTranslation } from 'react-i18next';

interface Props {
  openModal: boolean;
  handleClose: Function;
  handleAddEquipment: Function;
  setVinSearchData: Function;
  setVinSearchUsed: Function;
  vinsUsed: any;
  handleDuplicateVin: Function;
}

function VinModal({ openModal, handleClose, handleAddEquipment, setVinSearchData, setVinSearchUsed, vinsUsed, handleDuplicateVin }: Props) {
  const { t } = useTranslation();
  const VIN_MAX_LENGTH = 17;
  const [userInput, setUserInput] = useState('');
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleErrorThrown = (isInErrorState: boolean, errorTextToDisplay: string = '') => {
    setIsError(isInErrorState);
    setErrorText(errorTextToDisplay);
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const regExp = new RegExp('^[A-Za-z0-9]{0,17}$');
    if (regExp.test(e.target.value) === true) {
      setUserInput(e.target.value);
      handleErrorThrown(false, '');
    }
  };

  const handleSearchAnalytics = (isSearch: boolean = true) => {
    FirebaseAnalytics.sendEvent(
      FirebaseAnalytics.EVENTS.CLICK,
      FirebaseAnalytics.MODULES.ENTERPRISE,
      FirebaseAnalytics.PAGES.ADD_EQUIPMENT,
      {
        label: isSearch ? FirebaseAnalytics.LABELS.SEARCH_VIN : FirebaseAnalytics.LABELS.SKIP,
      },
    );
  };

  const handleSearch = () => {
    setIsLoading(true);
    handleSearchAnalytics();
    if (userInput.length === VIN_MAX_LENGTH) {
      const checkVin = async () => {
        try {
          if (vinsUsed.includes(userInput)) {
            handleDuplicateVin(userInput);
          } else {
            const data = await equipmentService.checkVin(userInput);

            if (
              data.errors.length !== 0 &&
              !data.errors.find((e: VinError) => e.errorCode === 2) &&
              !data.errors.find((e: VinError) => e.errorCode === 3) &&
              !data.errors.find((e: VinError) => e.errorCode === 4)
            ) {
              handleErrorThrown(true, t('Equipment not found. Please try another VIN code or Skip.'));
            }
            if (data.errors.length < 1) {
              setVinSearchUsed(true);
              data.vin = userInput;
              setVinSearchData(data);
              handleClose();
              handleAddEquipment();
            }
          }
        } catch (e) {
          handleErrorThrown(true, t('Error loading vehicle information. Please try again later.'));
        }
        setIsLoading(false);
      };
      checkVin();
    } else {
      setIsLoading(false);
      handleErrorThrown(true, t('Incomplete VIN. Please try another VIN code or skip.'));
    }
  };

  return (
    <Dialog open={openModal} onClose={() => handleClose()} maxWidth={'xs'}>
      <div className={css.vinModal}>
        {isLoading && <LoadingSpinner />}
        <div className={css.modalHeader}>
          <div className={css.headerText}>
            <h2>{t('Add Equipment using VIN')}</h2>
          </div>
          <div className={css.closeIcon}>
            <CloseIcon id="xIcon" onClick={() => handleClose()}></CloseIcon>
          </div>
        </div>
        <div className={css.modalBody}>
          <p>{t("By entering the VIN we can load the vehicle's information and facilitate the process of adding your new equipment.")}</p>
          <TextField
            error={isError}
            helperText={isError ? errorText : ''}
            className={css.textBox}
            type="text"
            placeholder={t('Input VIN code here')}
            value={userInput}
            onChange={handleInput}
          ></TextField>
        </div>
        <div className={css.modalFooter}>
          <div className={css.skipButton}>
            <p
              onClick={() => {
                setVinSearchUsed(false);
                handleSearchAnalytics(false);
                handleClose();
                handleAddEquipment();
              }}
              id="buttonSkip"
            >
              {t('Skip')}
            </p>
          </div>
          <div className={css.searchButton}>
            <Button id="buttonSearch" variant="contained" onClick={handleSearch}>
              {t('Search VIN')}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default VinModal;
