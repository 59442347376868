import css from './styles/AutoCompleteTextField.module.scss';
import { MenuItem, Paper, TextField, LinearProgress } from '@mui/material';
import Autosuggest from 'react-autosuggest';
import { Component, ReactElement, ReactNode, ReactPropTypes } from 'react';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import NumberFormat from 'react-number-format';

export interface Props extends Partial<ReactPropTypes> {
  classes?: any;
  inputClass?: any;
  value: any;
  maxResultsDisplayed?: any;
  onChange: any;
  onFocus?: any;
  error?: any;
  onBlur?: any;
  selectSuggestion: any;
  alwaysRenderSuggestions?: any;
  suggestions?: any;
  searchList?: any;
  getSuggestions?: (value: string, suggestions?: any[], name?: string) => string[] | string;
  renderSuggestion?: any;
  getSuggestionValue?: any;
  label?: any;
  className?: any;
  name?: any;
  shouldRenderSuggestions?: Function;
  disabled?: boolean;
  maskType?: 'phone';
  variant?: 'standard' | 'outlined' | 'filled';
  InputLabelProps?: any;
  placeholder?: string;
  maxLength?: number;
  renderSuggestionsContainer?: Function;
  onInput?: Function;
}

export default class AutoCompleteTextField extends Component<Props> {
  state: { suggestions: any; searchList?: any } = {
    suggestions: [],
    searchList: this.props.searchList || [],
  };

  isLoading = false;

  render(): ReactNode {
    const { value, onChange, onBlur, selectSuggestion, name, onFocus } = this.props as Props;
    const autoSuggestProps = {
      renderInputComponent: this.props.maskType === 'phone' ? this.renderPhoneNumberInputComponent : this.renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue: this.props.getSuggestionValue || this.getSuggestionValue,
      renderSuggestion: this.props.renderSuggestion ? this.handleCustomRenderSuggestions : this.renderSuggestion,
      onSuggestionSelected: selectSuggestion,
      alwaysRenderSuggestions: this.props.alwaysRenderSuggestions || false,
      shouldRenderSuggestions: this.props.shouldRenderSuggestions || (() => true),
      renderSuggestionsContainer: this.props.renderSuggestionsContainer || this.renderSuggestionsContainer,
    };
    return (
      <div className={this.props.className || css.AutoCompleteTextField}>
        {/* @ts-ignore */}
        <Autosuggest
          {...autoSuggestProps}
          inputProps={{
            value,
            onChange,
            // TODO: Figure out what should happen here
            // onError: error,
            onBlur,
            name,
            onFocus,
          }}
          theme={{
            container: css.container,
            suggestionsContainerOpen: css.suggestionsContainerOpen,
            suggestionsList: css.suggestionsList,
            suggestion: css.suggestion,
          }}
        />
      </div>
    );
  }

  renderSuggestionsContainer = (options: any) => {
    return (
      <Paper {...options.containerProps} square>
        {options.children}
      </Paper>
    );
  };

  handleCustomRenderSuggestions = (...args: any) => {
    return this.props.renderSuggestion(...args, this.props.name);
  };

  renderInputComponent = (inputProps: any): ReactElement => {
    inputProps['autoComplete'] = 'nope';
    const { inputRef = (): void => {}, ref, ...other } = inputProps;
    return (
      <>
        <TextField
          name={this.props.name}
          color="primary"
          className={`${this.props.inputClass || css.searchTextField}`}
          label={this.props.label}
          margin="normal"
          variant={this.props.variant || 'filled'}
          disabled={this.props.disabled}
          InputLabelProps={this.props.InputLabelProps}
          placeholder={this.props.placeholder}
          onInput={this.props.onInput}
          inputProps={{ maxLength: this.props.maxLength || -1 }}
          InputProps={{
            autoComplete: 'off',
            inputRef: (node): any => {
              ref(node);
              inputRef(node);
            },
          }}
          {...other}
        />
        {this.isLoading && <LinearProgress color="primary" className={css.searchLoader} />}
      </>
    );
  };

  renderPhoneNumberInputComponent = (inputProps: any): ReactElement => {
    inputProps['autoComplete'] = 'nope';
    const { inputRef = (): void => {}, ref, ...other } = inputProps;
    return (
      <>
        <NumberFormat
          type="tel"
          name={this.props.name}
          color="primary"
          placeholder={this.props.placeholder}
          className={`${this.props.inputClass || css.searchTextField}`}
          customInput={TextField}
          margin="normal"
          variant={this.props.variant || 'filled'}
          disabled={this.props.disabled}
          label={this.props.label}
          format="(###) ###-####"
          mask="_"
          InputLabelProps={this.props.InputLabelProps}
          InputProps={{
            inputRef: (node: any): any => {
              ref(node);
              inputRef(node);
            },
          }}
          {...other}
        />
        {this.isLoading && <LinearProgress color="primary" className={css.searchLoader} />}
      </>
    );
  };

  renderSuggestion = (suggestion: any, { query, isHighlighted }: any): ReactElement => {
    const matches = match(suggestion, query);
    const parts = parse(suggestion, matches);

    return (
      <MenuItem selected={isHighlighted} component="div">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            flexDirection: 'row',
          }}
        >
          <div>
            {parts.map((part: any, index: any) =>
              part.highlight ? (
                <span key={String(index)} style={{ fontWeight: 600, fontSize: '14px' }} className={css.highlightedPart}>
                  {part.text}
                </span>
              ) : (
                <strong key={String(index)} style={{ fontWeight: 300, fontSize: '14px' }} className={css.part}>
                  {part.text}
                </strong>
              ),
            )}
          </div>
        </div>
      </MenuItem>
    );
  };

  static getDerivedStateFromProps = (nextProps: any, prevState: any) => {
    return { searchList: nextProps['searchList'] || [] };
  };

  getSuggestions = (value: string, suggestions: string[]): string[] => {
    const inputValue = value;
    const inputLength = inputValue.length;

    let count = 0;
    if (inputLength === 0) {
      return [];
    } else {
      const max = this.props.maxResultsDisplayed || 5;
      return suggestions.filter((suggestion) => {
        if (!suggestion) {
          return false;
        }
        const keep = count < max && suggestion.toLowerCase().includes(inputValue.toLowerCase());
        if (keep) {
          count += 1;
        }
        return keep;
      });
    }
  };

  getSuggestionValue = (suggestion: any) => {
    return suggestion;
  };

  handleSuggestionsFetchRequested = async ({ value }: any): Promise<any> => {
    if (this.props.getSuggestions) {
      this.isLoading = true;
      let suggestions: any = await this.props.getSuggestions(value, this.props.searchList, this.props.name);
      if (suggestions === 'canceled') {
        suggestions = this.state.suggestions;
      } else {
        this.isLoading = false;
      }
      const max = this.props.maxResultsDisplayed || 5;
      this.setState({ suggestions: suggestions['splice'](0, max) });
    } else {
      this.setState({
        suggestions: this.getSuggestions(value, this.state.searchList),
      });
    }
  };

  handleSuggestionsClearRequested = (): void => {
    this.setState({
      suggestions: [],
    });
  };
}
