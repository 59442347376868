import React, { ReactPropTypes, useEffect } from 'react';
import css from './styles/BPOwnerModule.module.scss';
import { Truck } from 'types/Truck';
import { Trailer } from 'types/Trailer';
import TextField from '@mui/material/TextField';
import bindProperty from 'utils/bindProperty';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { BusinessPartner } from 'types/BusinessPartner';
import NumberFormat from 'react-number-format';
import FirebaseAnalytics from 'utils/firebaseAnalytics';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { filterPartnerByType, getBusinessPartners } from 'store/reducers/appSlice';
import infoIcon from '../../assets/orange-info-icon.svg';

interface Props extends Partial<ReactPropTypes> {
  equipment: Truck | Trailer;
  reduxEquipment: Truck | Trailer;
  setEquipment: any;
  currentValidation: any;
  setValidation: any;
  newPartner: BusinessPartner;
  setNewPartner: any;
  setWarningTab: any;
  radioSelection: string;
  setRadioSelection: Function;
  isVinDecodeEdit: boolean;
}

const BPOwnerModule: React.FC<Props> = ({
  equipment,
  reduxEquipment,
  setEquipment,
  currentValidation,
  setValidation,
  newPartner,
  setNewPartner,
  setWarningTab,
  radioSelection,
  setRadioSelection,
  isVinDecodeEdit,
}: Props) => {
  const { t } = useTranslation();
  const ownerFilter = React.useMemo(() => filterPartnerByType(4), []);
  const owners = useAppSelector(ownerFilter);
  const dispatch = useAppDispatch();

  const [bp, setBp] = React.useState<BusinessPartner | undefined>(undefined);

  useEffect(() => {
    dispatch(getBusinessPartners);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBp(owners.find((bp: BusinessPartner) => bp.id === equipment?.businessPartnerId));
  }, [equipment?.businessPartnerId, owners]);

  const createFullStreet = (bp: BusinessPartner) => {
    if (bp) {
      if (bp.address2) {
        return `${bp.address1} ${bp.address2}`;
      }
      if (bp.address1) {
        return bp.address1;
      }
      return '';
    }
    return '';
  };

  const handleRadioChange = (e: any) => {
    const { value } = e.target;
    if (radioSelection === 'select' && value === 'create' && reduxEquipment?.businessPartnerId !== equipment?.businessPartnerId) {
      setEquipment({ ...equipment, businessPartnerId: undefined });
    }
    let label: string;
    setRadioSelection(value);
    if (value === 'company') {
      setEquipment({ ...equipment, businessPartnerId: null });
      setNewPartner(new BusinessPartner(true));
      label = t('Company owned');
    } else if (value === 'create') {
      setEquipment({ ...equipment, businessPartnerId: null });
      label = t('Create new partner');
    } else {
      setNewPartner(new BusinessPartner(true));
      label = t('Owner from existing partner');
    }

    FirebaseAnalytics.sendEvent(
      FirebaseAnalytics.EVENTS.SELECT,
      FirebaseAnalytics.MODULES.ENTERPRISE,
      FirebaseAnalytics.PAGES.TRUCK_EDIT_MODAL,
      { label },
    );
  };

  const handleOwnerChange = (e: any) => {
    const { value } = e.target;
    const selectedOwner = owners.find((x: any) => x.id === value);
    setEquipment({
      ...equipment,
      businessPartnerId: value,
      ownerName: selectedOwner.fullName,
      city: selectedOwner.city,
      address1: selectedOwner.address1,
      address2: selectedOwner.address2,
      state: selectedOwner.state,
      postalCode: selectedOwner.postalCode,
    });
  };

  const handleNewPartnerChange = function (e: any) {
    validate(e);
    let { name, value } = e.target;
    if (name === 'phoneNumber') {
      value = value.replace(/\D/g, '');
    }
    setNewPartner({ ...newPartner, [name]: value });
  };

  const validate = (event: any) => {
    const { name, value } = event.target;
    switch (name) {
      case 'firstName':
        if (value.length < 1) {
          setValidation({
            ...currentValidation,
            partnerFirstName: { hasError: true, message: t('First name required') },
          });
          setWarningTab(1);
        } else {
          setValidation({
            ...currentValidation,
            partnerFirstName: { hasError: false, message: '' },
          });
          setWarningTab(null);
        }
        break;
      case 'email':
        if (!validateEmail(value)) {
          setValidation({
            ...currentValidation,
            partnerEmail: { hasError: true, message: t('Invalid email address') },
          });
          setWarningTab(1);
        } else {
          setValidation({
            ...currentValidation,
            partnerEmail: { hasError: false, message: '' },
          });
          setWarningTab(null);
        }
        break;
      case 'phoneNumber':
        if (value.replace(/\D/g, '').length !== 10) {
          setValidation({
            ...currentValidation,
            partnerPhone: { hasError: true, message: t('Invalid phone number') },
          });
          setWarningTab(1);
        } else {
          setValidation({
            ...currentValidation,
            partnerPhone: { hasError: false, message: '' },
          });
          setWarningTab(null);
        }
        break;

      default:
        setValidation({
          ...currentValidation,
          [name]: { hasError: false, message: '' },
        });
        setWarningTab(null);
        break;
    }
  };

  const validateEmail = (email: string) => {
    // eslint-disable-next-line no-useless-escape
    const re = /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/;
    return re.test(email);
  };

  return (
    <div className={css.container}>
      {isVinDecodeEdit && (
        <div className={css.vinEditMessage}>
          <img src={infoIcon} width={'14px'} height={'14px'} alt="infoIcon" />
          {` ${t('You are about to edit data')}`}
        </div>
      )}
      <div className={css.row}>
        <RadioGroup value={radioSelection} onChange={handleRadioChange} className={css.radioGroup}>
          <FormControlLabel value="company" control={<Radio />} label={t('Company owned')} className={css.radioLabel} />
          <FormControlLabel value="select" control={<Radio />} label={t('Select owner from existing partner')} className={css.radioLabel} />
          <FormControlLabel value="create" control={<Radio />} label={t('Create new partner')} className={css.radioLabel} />
        </RadioGroup>
      </div>
      {radioSelection === 'select' && (
        <>
          <div className={css.row}>
            <FormControl fullWidth className={css.select}>
              {!equipment?.businessPartnerId && (
                <InputLabel disableAnimation={true} shrink={false} focused={false}>
                  {t('Select Owner')}
                </InputLabel>
              )}
              <Select
                value={bindProperty(equipment?.businessPartnerId)}
                variant={'filled'}
                onChange={handleOwnerChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  // getContentAnchorEl: null,
                  classes: {
                    list: css.option,
                  },
                }}
              >
                {owners.map((bp: BusinessPartner) => (
                  <MenuItem value={bp.id} key={bp.id}>
                    {bp.fullName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              name="ownerName"
              margin="dense"
              label={t('Full Name')}
              variant={'filled'}
              value={bp?.fullName ?? ''}
              InputLabelProps={{ shrink: !!bp?.firstName }}
              disabled
            />
          </div>
          <div className={css.row}>
            <TextField
              name="street"
              margin="dense"
              label={t('Street')}
              variant={'filled'}
              value={createFullStreet(bp as BusinessPartner)}
              InputLabelProps={{ shrink: !!bp?.address1 }}
              disabled
            />
            <TextField
              name="city"
              margin="dense"
              label={t('City')}
              variant={'filled'}
              value={bindProperty(bp?.city)}
              InputLabelProps={{ shrink: !!bp?.city }}
              disabled
            />
          </div>
          <div className={css.row}>
            <TextField
              name="state"
              margin="dense"
              label={t('State')}
              variant={'filled'}
              value={bindProperty(bp?.state)}
              InputLabelProps={{ shrink: !!bp?.state }}
              disabled
            />
            <TextField
              name="postalCode"
              margin="dense"
              label={t('Zip Code')}
              variant={'filled'}
              value={bindProperty(bp?.postalCode)}
              InputLabelProps={{ shrink: !!bp?.postalCode }}
              disabled
            />
          </div>
        </>
      )}
      {radioSelection === 'create' && (
        <>
          <div className={css.row}>
            <TextField
              name="firstName"
              margin="dense"
              label={`${t('First Name (required)')}`}
              variant={'filled'}
              value={bindProperty(newPartner?.firstName)}
              onChange={handleNewPartnerChange}
              error={currentValidation.partnerFirstName.hasError}
              helperText={currentValidation.partnerFirstName.message}
            />
            <TextField
              name="lastName"
              margin="dense"
              label={t('Last Name')}
              variant={'filled'}
              value={bindProperty(newPartner?.lastName)}
              onChange={handleNewPartnerChange}
            />
          </div>
          <div className={css.row}>
            <NumberFormat
              type="tel"
              name="phoneNumber"
              color="primary"
              customInput={TextField}
              margin="dense"
              variant={'filled'}
              label={`${t('Phone Number (required)')}`}
              format="(###) ###-####"
              mask="_"
              error={currentValidation.partnerPhone.hasError}
              helperText={currentValidation.partnerPhone.message}
              value={bindProperty(newPartner?.phoneNumber)}
              onChange={handleNewPartnerChange}
            />
            <TextField
              name="email"
              margin="dense"
              label="Email Address"
              variant={'filled'}
              error={currentValidation.partnerEmail.hasError}
              helperText={currentValidation.partnerEmail.message}
              value={bindProperty(newPartner?.email)}
              onChange={handleNewPartnerChange}
            />
          </div>
          <div className={css.row}>
            <TextField
              name="businessPartnerName"
              margin="dense"
              label={t('Company Name')}
              variant={'filled'}
              value={bindProperty(newPartner?.businessPartnerName)}
              onChange={handleNewPartnerChange}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default BPOwnerModule;
