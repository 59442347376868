import React, { ReactPropTypes } from 'react';
import css from './styles/SearchInput.module.scss';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import Close from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

interface Props extends Partial<ReactPropTypes> {
  value?: string;
  setValue: (value: string) => void;
  onClear: () => void;
  placeHolder?: string;
}

const SearchInput: React.FC<Props> = ({ value, setValue, placeHolder, onClear }: Props) => {
  return (
    <div className={css.container}>
      <TextField
        placeholder={placeHolder}
        variant="outlined"
        className={css.field}
        value={value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setValue(event.target.value || '');
        }}
        InputLabelProps={{ shrink: false }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" classes={{ root: !!value ? css.adornmentButton : css.adornmentIcon }}>
              {!!value ? (
                <IconButton className={css.button} onClick={onClear}>
                  <Close />
                </IconButton>
              ) : (
                <Search />
              )}
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default SearchInput;
