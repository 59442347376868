import Dialog from '@mui/material/Dialog';
import css from './styles/UnsavedChangesDialog.module.scss';
import { ReactPropTypes } from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

interface Props extends Partial<ReactPropTypes> {
  onDialogClose: Function;
  onPrimaryActionCompleted: Function;
  open: boolean;
  title?: string;
  message?: string;
}

export default function UnsavedChangesDialog({ onDialogClose, onPrimaryActionCompleted, open, title, message }: Props) {
  const { t } = useTranslation();
  const handleClose = (event: any) => {
    onDialogClose(event);
  };

  const handlePrimaryAction = () => {
    onPrimaryActionCompleted();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby={css.formDialogTitle}
      classes={{ paper: css.dialogContainer, root: css.root }}
    >
      <div className={css.content}>
        <div className={css.title}>{title || t('Do you want to quit editing?')}</div>
        <div className={css.message}>{message || t('Quitting without saving cannot be undone')}</div>
        <div className={css.actionItems}>
          <Button className={css.cancelButton} variant="outlined" onClick={handleClose}>
            {t('CONTINUE EDITING')}
          </Button>
          <Button className={css.proceedButton} variant="outlined" onClick={handlePrimaryAction}>
            {t('QUIT')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
